import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { enumLanguage } from "src/types/enumeration";

import fr from "./translations/fr";

const resources = {
  fr: { translation: fr },
};

// eslint-disable-next-line import/no-named-as-default-member
i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    keySeparator: false,
    lng: enumLanguage.fr,
    fallbackLng: enumLanguage.fr,
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
