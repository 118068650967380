import { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router";
import Header from "src/components/Header/Header";
import { Box } from "@mui/material";
import Footer from "src/components/Footer/Footer";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  pageContent: { flexGrow: 1 },
}));

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <Header />
      <Box className={classes.pageContent}>{children || <Outlet />}</Box>
      <Footer />
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node,
};

export default BaseLayout;
