import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarKey, useSnackbar } from 'notistack';
import { FC } from 'react';

type Props = { snackbarKey: SnackbarKey };

const SnackbarCloseButton: FC<Props> = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  const onClick = () => {
    closeSnackbar(snackbarKey);
  };

  return (
    <IconButton size="small" onClick={onClick}>
      <CloseIcon htmlColor="white" />
    </IconButton>
  );
};

export default SnackbarCloseButton;

export const injectClose = (snackbarKey: SnackbarKey) => (
  <SnackbarCloseButton snackbarKey={snackbarKey} />
);
