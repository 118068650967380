import get from "lodash-es/get";
import { DropEvent, FileRejection } from "react-dropzone";
import { enumDatatableFilterType, enumLanguage } from "src/types/enumeration";
import { FilterValueType, MyFile, ObjectUnionType } from "src/types/logic";
import htmlToReact from "html-to-react";
import { Maybe } from "src/generated/graphql";
import { DEFAULT_BASE_64_IMG } from "src/constants/image";
import { OnDropEventType } from "src/components/DropZone/MyDropZone";
import i18next from "i18next";
import { ApolloError } from "@apollo/client";
import { UseFormSetError } from "react-hook-form";
import { GraphQLError } from "graphql";
import moment from "moment";
import showdown from "showdown";
import loadImage from "blueimp-load-image";

interface OwnGraphQLError<T> extends GraphQLError {
  field?: keyof T;
}

export const handleErrorForm = (
  error: ApolloError,
  setError: UseFormSetError<any>
) => {
  if (error && error.graphQLErrors) {
    error.graphQLErrors.map((graphQLError: OwnGraphQLError<any>) => {
      if (graphQLError.field) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        return setError(graphQLError.field, {
          type: "manual",
          message: i18next.t(graphQLError.message),
        });
      }
      return false;
    });
  }
};

export const downloadBase64File = (
  source: string | null,
  mimeType: Maybe<string> | undefined,
  base64Data: Maybe<string> | undefined,
  filename: Maybe<string> | undefined
) => {
  if (((mimeType && base64Data) || source) && filename) {
    const linkSource = source
      ? source
      : `data:${mimeType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = filename;
    downloadLink.click();
  }
};

export const previewBase64File = (
  mimeType: Maybe<string> | undefined,
  base64Data: Maybe<string> | undefined,
  filename: Maybe<string> | undefined
) => {
  if (mimeType && base64Data && filename) {
    const linkSource = `data:${mimeType};base64,${base64Data}`;
    const pdfWindow = window.open();
    (pdfWindow as Window).document.write(
      `<iframe width='100%' height='100%' src='${linkSource}'></iframe>`
    );
  }
};

export const sortStringAsc = (
  elements: ObjectUnionType[],
  accessor: Array<string>
) =>
  elements.sort((a: ObjectUnionType, b: ObjectUnionType) => {
    if (get(a, accessor) < get(b, accessor)) {
      return -1;
    }
    if (get(a, accessor) > get(b, accessor)) {
      return 1;
    }
    return 0;
  });

export const defineImgSrc = (
  base64: Maybe<string> | undefined,
  mimeType: Maybe<string> | undefined
): string => {
  if (base64 && mimeType) {
    return `data:${mimeType};base64,${base64}`;
  }
  return DEFAULT_BASE_64_IMG;
};

export const uuidv4 = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const base64toBlob = (data: string, type: string) => {
  const base64WithoutPrefix = data.split(",")[1];
  const bytes = atob(base64WithoutPrefix);
  let { length } = bytes;
  const out = new Uint8Array(length);

  // eslint-disable-next-line no-plusplus
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  return new Blob([out], { type });
};

export const getDocumentUrl = (data: string, type: string) => {
  const base64 = `data:${type};base64,${data}`;
  const blob = base64toBlob(base64, type);
  return URL.createObjectURL(blob);
};

export const matchFiltersWithStrapi = (
  filters: Record<
    string,
    {
      filterValue: FilterValueType;
      filterType: string;
    }
  >
) =>
  Object.entries(filters).reduce((acc, [key, value]) => {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const previousAnd: Array<object> = get(acc, ["$and"], []);

    if (
      value.filterType === enumDatatableFilterType.contain ||
      value.filterType === enumDatatableFilterType.select
    ) {
      const and = [
        ...previousAnd,
        {
          [key]: {
            $like:
              value.filterType === enumDatatableFilterType.select
                ? `${value.filterValue}`
                : `%${value.filterValue}%`,
          },
        },
      ];
      return {
        ...acc,
        ...(and.length > 0 && { $and: and }),
      };
    }
    if (value.filterType === enumDatatableFilterType.between) {
      const { start, end } = value.filterValue as {
        start: number | null;
        end: number | null;
      };
      const and = [
        ...previousAnd,
        { [key]: { $gte: start } },
        { [key]: { $lte: end } },
      ];
      return {
        ...acc,
        ...(and.length > 0 && { $and: and }),
      };
    }
    if (value.filterType === enumDatatableFilterType.multiselect) {
      const previousOr = Object.entries(previousAnd).find(
        ([key]) => key === "$or"
      );
      // eslint-disable-next-line @typescript-eslint/ban-types
      const or: Array<object> = (value.filterValue as Array<string>).map(
        (e: string) => ({
          [key]: { $like: `${e}` },
        })
      );

      // eslint-disable-next-line @typescript-eslint/ban-types
      const finalOr: Array<object> = [...get(previousOr, ["$or"], []), ...or];

      // eslint-disable-next-line @typescript-eslint/ban-types
      const filterOr = Object.entries(previousAnd).reduce(
        (acc: Array<object>, [key, value]) => {
          if (key !== "$or") {
            return [...acc, value];
          }
          return acc;
        },
        []
      );

      // eslint-disable-next-line @typescript-eslint/ban-types
      const finalAnd: Array<object> = [...filterOr];

      if (finalOr.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-types
        finalAnd.push({ $or: finalOr } as object);
      }

      return {
        ...acc,
        ...(finalAnd.length > 0 && { $and: finalAnd }),
      };
    }

    return acc;
  }, {});

export const getFilesFromEvent = async (
  event: DropEvent
): Promise<(File | DataTransferItem)[]> => {
  const files =
    (event.target as any).files || (event as any).dataTransfer.files;

  const promises: Promise<File | DataTransferItem>[] = [];
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    const promise: Promise<File | DataTransferItem> = new Promise((resolve) => {
      const image = new Image();
      let url: string;
      // eslint-disable-next-line func-names
      image.onload = function () {
        file.width = image.width;
        file.height = image.height;
        resolve(file);
      };
      // eslint-disable-next-line prefer-const
      url = URL.createObjectURL(file);
      image.src = url;
    });
    promises.push(promise);
  }
  // eslint-disable-next-line @typescript-eslint/return-await
  return await Promise.all(promises);
};

export const dropZoneValidator =
  // eslint-disable-next-line consistent-return
  (width?: number, height?: number) => (file: File) => {
    if (
      width &&
      (file as File & { width: number }).width !== width &&
      height &&
      (file as File & { height: number }).height !== height
    ) {
      return {
        code: "width-and-height",
        message: `Image width must be ${width}px and ${height}px`,
      };
    }
    if (width && (file as File & { width: number }).width !== width) {
      return {
        code: "width",
        message: `Image width must be ${width}px`,
      };
    }
    if (height && (file as File & { height: number }).height !== height) {
      return {
        code: "height",
        message: `Image height must be ${height}px`,
      };
    }
  };

export const onReactDropZoneDrop =
  (onDropEvent: OnDropEventType) =>
  async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const filePromises = acceptedFiles.map(
      (acceptedFile) =>
        new Promise(async (resolve, reject) => {
          try {
            loadImage(
              acceptedFile,
              (img: any, data) => {
                console.log("data", data);
                console.log("eeee", data?.exif?.get("Orientation"));
                img.toBlob(async (blob: Blob) => {
                  console.log("blob", blob);
                  const url = URL.createObjectURL(blob);
                  resolve({
                    uuid: uuidv4(),
                    file: Object.assign(acceptedFile, {
                      preview: url,
                    }),
                  });
                }, "image/jpeg");
              },
              { orientation: false, canvas: true, meta: true }
            );
          } catch (err) {
            reject(err);
          }
        })
    );
    const fileInfos: Array<{ uuid: string; file: MyFile }> = (await Promise.all(
      filePromises
    )) as any;
    onDropEvent(fileInfos, fileRejections);
  };

export const parseHtmlStringToReactElement = (
  htmlString: Maybe<string> | undefined
) => {
  if (htmlString) {
    const htmlToReactParser = new htmlToReact.Parser();
    return htmlToReactParser.parse(htmlString);
  }
  return null;
};

export const transformTimeString = (time: Maybe<string> | undefined) => {
  if (time) {
    return moment(time, "HH:mm").format("hh:mm a");
  }
  return "";
};

export const base64PDFtoBlob = (data: string) => {
  const base64WithoutPrefix = data.substr(
    "data:application/pdf;base64,".length
  );

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};

export const downloadZipString = (filename: string, data: string) => {
  var element = document.createElement("a");
  element.setAttribute("href", "data:text/plain;base64," + data);
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const hexToRgb = (hex: string | undefined) => {
  if (hex) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return { r, g, b }; // return an object
  }
  return { r: 0, g: 0, b: 0 };
};

export const hexToCMYK = (hex: string | undefined | null) => {
  if (hex) {
    let computedC = 0;
    let computedM = 0;
    let computedY = 0;
    let computedK = 0;
    hex = hex.charAt(0) === "#" ? hex.substring(1, 7) : hex;

    if (hex.length !== 6) {
      alert("Invalid length of the input hex value!");
      return { c: 0, m: 0, y: 0, k: 0 };
    }
    if (/[0-9a-f]{6}/i.test(hex) !== true) {
      alert("Invalid digits in the input hex value!");
      return { c: 0, m: 0, y: 0, k: 0 };
    }

    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);

    // BLACK
    if (r === 0 && g === 0 && b === 0) {
      computedK = 1;
      return {
        c: Math.round(0 * 100),
        m: Math.round(0 * 100),
        y: Math.round(0 * 100),
        k: Math.round(1 * 100),
      };
    }

    computedC = 1 - r / 255;
    computedM = 1 - g / 255;
    computedY = 1 - b / 255;

    var minCMY = Math.min(computedC, Math.min(computedM, computedY));

    computedC = (computedC - minCMY) / (1 - minCMY);
    computedM = (computedM - minCMY) / (1 - minCMY);
    computedY = (computedY - minCMY) / (1 - minCMY);
    computedK = minCMY;

    return {
      c: Math.round(computedC * 100),
      m: Math.round(computedM * 100),
      y: Math.round(computedY * 100),
      k: Math.round(computedK * 100),
    };
  }
  return {
    c: Math.ceil(0 * 100),
    m: Math.ceil(0 * 100),
    y: Math.ceil(0 * 100),
    k: Math.ceil(0 * 100),
  };
};

export const getSelectLabelFromBOKey = (BOKey: string, value: Maybe<string>) =>
  `select_key_${BOKey}_${value}`;

export const convertWYSIWYGText = (text: string) => {
  if (text) {
    const converter = new showdown.Converter();
    const html = converter.makeHtml(text);
    return parseHtmlStringToReactElement(html);
  }
  return null;
};

export const defineCulture = (culture: string) => {
  if (enumLanguage.fr === culture) {
    return "fr";
  }
};
