import { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import router from 'src/router/router';

const ContentRouter: FC = () => {
  const content = useRoutes(router);

  return <>{content}</>;
};
export default ContentRouter;
