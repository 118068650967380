import { Box } from "@mui/material";
import { FC } from "react";

type Props = {
  fontSize: number;
  borderWidth: number;
  color: string;
};

const sxStyles = {
  container: ({ fontSize }: Props) => ({
    width: `${fontSize}px`,
    height: `${fontSize}px`,
    position: "relative",
  }),
  spinner: ({ fontSize, borderWidth, color }: Props) => ({
    position: "relative",
    "@keyframes spinner": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
    "&:before": {
      content: '""',
      boxSizing: "border-box",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: `${fontSize}px`,
      height: `${fontSize}px`,
      marginLeft: `${-(fontSize / 2)}px`,
      borderRadius: `${2.5 * fontSize}px`,
      borderTop: `${borderWidth}px solid ${color}`,
      borderRight: `${borderWidth}px solid transparent`,
      animation: `spinner 1.2s linear infinite`,
    },
  }),
};

const Spinner: FC<Props> = ({ fontSize, borderWidth, color }) => {
  return (
    <Box sx={sxStyles.container({ fontSize, borderWidth, color })}>
      <Box sx={sxStyles.spinner({ fontSize, borderWidth, color })} />
    </Box>
  );
};

export default Spinner;
