import { createContext, useState, Dispatch, SetStateAction, FC } from "react";

// eslint-disable-next-line @typescript-eslint/ban-types
type initialTechnicalsProps = {
  technicals: Record<string, any>;
  setTechnicals: Dispatch<SetStateAction<any>>;
  setInTechnicalType: (technicalType: string, newValues: Array<any>) => void;
};

const initialTechnicals: initialTechnicalsProps = {
  technicals: {},
  setTechnicals: () => {
    /* DO SOMETHING */
  },
  setInTechnicalType: (technicalType: string, newValues: Array<any>) => {
    /* DO SOMETHING */
  },
};

export const TechnicalContext = createContext(initialTechnicals);

type Props = {
  children: JSX.Element;
};

export const TechnicalProvider: FC<Props> = ({ children }) => {
  const [technicals, setTechnicals] = useState(initialTechnicals.technicals);

  const setInTechnicalType = (technicalType: string, newValues: Array<any>) => {
    setTechnicals({ ...technicals, [technicalType]: newValues });
  };

  return <TechnicalContext.Provider value={{ technicals, setTechnicals, setInTechnicalType }}>{children}</TechnicalContext.Provider>;
};

export default TechnicalProvider;
