import { CssBaseline, ThemeProvider } from "@mui/material";
import { PureLightTheme } from "src/theme/PureLightTheme";
import TableProvider from "./providers/TableProvider";
import TechnicalProvider from "./providers/TechnicalProvider";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
/* import { SidebarProvider } from "src/providers/SidebarProvider"; */
import ScrollTop from "src/hooks/useScrollTop";
/* import WebsiteSettingsProvider from "./providers/WebsiteSettingsProvider"; */
import { SnackbarProvider } from "notistack";
/* import UserProvider from "./providers/UserProvider"; */
/* import TranslationsProvider from "./providers/TranslationsProvider"; */
import { injectClose } from "./components/Snackbar/SnackbarCloseButton";
import { getToken, removeTokenCookie } from "./auth/session";
import { onError } from "@apollo/client/link/error";
import ContentRouter from "./ContentRouter";
import { LOGIN_PATH } from "./accessControl/route";
import { FC } from "react";
import { SidebarProvider } from "./providers/SidebarProvider";
import TranslationsProvider from "./providers/TranslationsProvider";
import UserProvider from "./providers/UserProvider";
import "./styles/index.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/fr";
import { createUploadLink } from "apollo-upload-client";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MomentUtils from "@date-io/moment";

const App: FC = () => {
  const middlewareLink = new ApolloLink((operation, forward) => {
    const token = getToken();
    operation.setContext({
      // headers: { 'Content-Type': 'application/x-www-form-urlencoded', ...(token && { authorization: token }) }
      headers: { ...(token && { Authorization: "Bearer " + token }) },
    });
    return forward(operation);
  });

  const appCache = new InMemoryCache();

  const errorLink = onError((errorResponse) => {
    console.log("errorResponse", errorResponse);
    const { graphQLErrors, networkError } = errorResponse;
    if (graphQLErrors) {
      console.log("graphQLErrors", graphQLErrors);
      // eslint-disable-next-line array-callback-return
      graphQLErrors.map(({ message, locations, path }) => {
        // eslint-disable-next-line no-console
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        // console.log('ERROR', JSON.parse(message));
      });

      const f: { code: number | string; field: string; message: string } =
        graphQLErrors?.find(
          (e) =>
            (e as unknown as { code: number; field: string; message: string })
              ?.code
        ) as any;

      if (f?.code === 403 && f.field === "jwt_token") {
        removeTokenCookie();
        window.localStorage.clear();
        window.location.replace(LOGIN_PATH);
      }

      if (networkError) {
        // eslint-disable-next-line no-console
        console.log(`[Network error]: ${networkError}`);
      }
    }
  });

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      middlewareLink,
      createUploadLink({
        /*    uri: "http://0.0.0.0:8080/graphql", */
        uri: "https://acca-vendays-montalivet.herokuapp.com/graphql",
      }),
    ]),
    credentials: "include",
    // @ts-ignore
    fetchOptions: {
      mode: "no-cors",
    },
    cache: appCache,
  });

  return (
    <HelmetProvider>
      <SidebarProvider>
        <BrowserRouter>
          <ScrollTop />
          <ApolloProvider client={apolloClient}>
            <TranslationsProvider>
              <UserProvider>
                {/*     <WebsiteSettingsProvider> */}
                <SnackbarProvider
                  action={injectClose}
                  maxSnack={3}
                  autoHideDuration={5000}
                  anchorOrigin={{ horizontal: "center", vertical: "top" }}
                >
                  <ThemeProvider theme={PureLightTheme}>
                    <TableProvider>
                      <TechnicalProvider>
                        <LocalizationProvider dateAdapter={MomentUtils}>
                          <CssBaseline />
                          <ContentRouter />
                        </LocalizationProvider>
                      </TechnicalProvider>
                    </TableProvider>
                  </ThemeProvider>
                </SnackbarProvider>
                {/*        </WebsiteSettingsProvider> */}
              </UserProvider>
            </TranslationsProvider>
          </ApolloProvider>
        </BrowserRouter>
      </SidebarProvider>
    </HelmetProvider>
  );
};
export default App;
