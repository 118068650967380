import { createContext, useState, Dispatch, SetStateAction, useEffect, FC, ReactNode } from "react";
import i18next from "i18next";
import { enumLanguage } from "src/types/enumeration";

// eslint-disable-next-line @typescript-eslint/ban-types
type initialTranslationsProps = {
  language: enumLanguage;
  setLanguage: Dispatch<SetStateAction<enumLanguage>>;
  loading: boolean;
};

const initialTranslations: initialTranslationsProps = {
  language: enumLanguage.fr,
  setLanguage: () => {
    /* DO SOMETHING */
  },
  loading: false,
};

export const TranslationsContext = createContext(initialTranslations);

type Props = {
  children?: ReactNode;
};

export const TranslationsProvider: FC<Props> = ({ children }) => {
  const [language, setLanguage] = useState(enumLanguage.fr);
  const [globalLoading] = useState<boolean>(false);

  /*   const { loading } = useQuery<{
    getTranslations: Query["getTranslations"];
  }>(GetTranslationsQuery, {
  fetchPolicy: "no-cache",
    nextFetchPolicy: "cache-first",
    variables: { language },
    onCompleted: (data) => {
      setGlobalLoading(true);
      const translationsJSON = JSON.parse(data?.getTranslations?.translations as string);
      i18next.addResources(language, "translation", translationsJSON);
      i18next.changeLanguage(language);

      setGlobalLoading(false);
    },
  });
 */
  useEffect(() => {
    i18next.changeLanguage(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return <TranslationsContext.Provider value={{ language, setLanguage, loading: globalLoading /* || loading */ }}>{children}</TranslationsContext.Provider>;
};

export default TranslationsProvider;
