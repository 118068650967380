import cookie from 'cookie';
import { IncomingMessage } from 'http';

const TOKEN_KEY = 'token';

export const setCookie = (key: string, value: string) => {
  document.cookie = cookie.serialize(key, value, {
    maxAge: 30 * 24 * 60 * 60, // 30 days
    path: '/'
  });
};

export const removeCookie = (key: string) => {
  document.cookie = cookie.serialize(key, '', {
    maxAge: -1, // Expire the cookie immediately
    path: '/'
  });
};

export const getToken = (req?: IncomingMessage | undefined) => {
  let str = '';
  if (req && req.headers.cookie) {
    str = req.headers.cookie;
  } else if (typeof document !== 'undefined' && document.cookie) {
    str = document.cookie;
  }
  return cookie.parse(str).token;
};

export const setTokenCookie = (token: string) => {
  setCookie(TOKEN_KEY, token);
};

export const removeTokenCookie = () => {
  removeCookie(TOKEN_KEY);
};
