import { styled, Typography } from '@mui/material';

const SectionHeading = styled(Typography)(
  ({ theme }) => `
          font-weight: ${theme.typography.fontWeightBold};
          color: ${theme.palette.secondary.main};
          display: block;
          padding: ${theme.spacing(2, 2, 0)};
  `
);

export default SectionHeading;
