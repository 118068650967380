import { createContext, useEffect, Dispatch, FC, useReducer, ReactNode } from "react";

import { ASSETS_TYPE, CONTACTS_TYPE, CONTACT_GROUPS_TYPE, EMAILS_TYPE, EVENTS_TYPE, MEMBERS_TYPE, SPONSORS_TYPE } from "src/constants/entity";
import { EmailEntity } from "src/generated/graphql";
import { EntityType } from "src/constants/entity";
import { enumDatatableFilterType } from "src/types/enumeration";
import { FilterValueType } from "src/types/logic";
import { matchFiltersWithStrapi } from "src/utils/utils";
import { SxProps } from "@mui/material";

const LOCAL_STORAGE_KEY = "table";

export type Column = {
  Header: JSX.Element;
  style?: { headerStyle?: SxProps; filterStyle?: SxProps };
  accessor: string;
  useSorting: boolean;
  label: string;
  Filter: JSX.Element;
  Cell: (obj: DatatableObject) => {
    cells: Array<JSX.Element>;
    rowSpan: number;
  };
};

export type StringKey<D> = Extract<keyof D, string>;
export type IdType<D> = StringKey<D> | string;
export interface SortingRule<D> {
  id: IdType<D>;
  desc?: boolean | undefined;
}

type State = Record<EntityType, InitProps<DatatableObject>>;
type InitProps<T> = {
  forceRefetch: boolean;
  pageSize: number;
  page: number;
  sortBy: SortingRule<T>[];
  filters: Record<
    string,
    {
      filterValue: FilterValueType;
      filterType: string;
    }
  >;
  columns: Array<{
    accessor: string;
    Header: string;
    Filter: JSX.Element;
  }>;
  columnsSort: Array<string>;
  selectedObjects: Array<any>;
  hiddenColumnsAccessor: Array<string>;
  data: DatatableObject[];
  totalCount: number;
};
type ACTIONTYPE =
  | {
      type: TableContextActions.GET_LOCAL_STORAGE_STATE;
      payload: { state: State };
    }
  | {
      type: TableContextActions.UPDATE_ENTITYTYPE_ATTRIBUTE;
      // eslint-disable-next-line @typescript-eslint/ban-types
      payload: {
        entityType: EntityType;
        attribute: string;
        value: number | Array<string> | any[] | SortingRule<DatatableObject>[];
      };
    }
  | {
      type: TableContextActions.PERSIST_PAGE_SIZE;
      payload: { entityType: EntityType; pageSize: number };
    }
  | {
      type: TableContextActions.PERSIST_SELECT_OBJECT;
      payload: {
        entityType: EntityType;
        obj: DatatableObject;
        checked: boolean;
      };
    }
  | {
      type: TableContextActions.CLEAR_SELECTED_OBJECTS;
      payload: { entityType: EntityType };
    }
  | {
      type: TableContextActions.PERSIST_FILTERS;
      payload: {
        entityType: EntityType;
        newFilter: {
          [x: string]: {
            filterValue:
              | string
              | {
                  start: number | undefined;
                  end: number | undefined;
                }
              | Array<string>
              | undefined;
            filterType: string;
          };
        };
      };
    }
  | {
      type: TableContextActions.CLEAR_FILTERS;
      payload: {
        entityType: EntityType;
      };
    }
  | {
      type: TableContextActions.PERSIST_SORT_BY;
      payload: {
        entityType: EntityType;
        sortBy: SortingRule<DatatableObject>[];
      };
    }
  | {
      type: TableContextActions.HIDE_COLUMN;
      payload: { entityType: EntityType; accessor: string; hide: boolean };
    }
  | {
      type: TableContextActions.FORCE_REFRESH;
      payload: { entityType: EntityType; refresh: boolean };
    }
  | {
      type: TableContextActions.SET_DATAS;
      payload: {
        entityType: EntityType;
        data: DatatableObject[];
        totalCount?: number;
      };
    };

export type DatatableObject = EmailEntity;

export enum TableContextActions {
  GET_LOCAL_STORAGE_STATE = "GET_LOCAL_STORAGE_STATE",
  UPDATE_ENTITYTYPE_ATTRIBUTE = "UPDATE_ENTITYTYPE_ATTRIBUTE",
  PERSIST_PAGE_SIZE = "PERSIST_PAGE_SIZE",
  PERSIST_SELECT_OBJECT = "PERSIST_SELECT_OBJECT",
  CLEAR_SELECTED_OBJECTS = "CLEAR_SELECTED_OBJECTS",
  PERSIST_FILTERS = "PERSIST_FILTERS",
  CLEAR_FILTERS = "CLEAR_FILTERS",
  PERSIST_SORT_BY = "PERSIST_SORT_BY",
  HIDE_COLUMN = "HIDE_COLUMN",
  FORCE_REFRESH = "FORCE_REFRESH",
  SET_DATAS = "SET_DATAS",
}

const init = {
  forceRefetch: false,
  pageSize: 100,
  page: 1,
  sortBy: [],
  filters: {},
  columns: [],
  columnsSort: [],
  selectedObjects: [],
  hiddenColumnsAccessor: [],
  data: [],
  totalCount: 0,
};

const initialState: State = {
  [EMAILS_TYPE]: {
    ...init,
    filters: {},
    sortBy: [],
  },
  [MEMBERS_TYPE]: {
    ...init,
    filters: {},
    sortBy: [],
  },
  [CONTACT_GROUPS_TYPE]: {
    ...init,
    filters: {},
    sortBy: [],
  },
  [CONTACTS_TYPE]: {
    ...init,
    filters: {},
    sortBy: [],
  },
  [SPONSORS_TYPE]: {
    ...init,
    filters: {},
    sortBy: [],
  },
  [EVENTS_TYPE]: {
    ...init,
    filters: {},
    sortBy: [],
  },
  [ASSETS_TYPE]: {
    ...init,
    filters: {},
    sortBy: [],
  },
};

const applyColumnSort = (newState: Record<EntityType, InitProps<DatatableObject>>) => {
  const withColumnsSort = Object.keys(newState).reduce((acc: Record<string, InitProps<DatatableObject>>, entityType) => {
    const { columns, ...rest } = newState[entityType as EntityType];
    if (columns) {
      acc[entityType] = { ...rest, columns: [] };
    } else {
      acc[entityType] = newState[entityType as EntityType];
    }
    return acc;
  }, {});
  return withColumnsSort;
};

const setStateInLocalStorage = (newState: Record<EntityType, InitProps<DatatableObject>>) => {
  const columnSortApplied = applyColumnSort(newState);
  const cleaned = Object.entries(columnSortApplied).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: { ...value, data: [], selectedObjects: [] },
    }),
    {}
  );
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(cleaned));
  return columnSortApplied;
};

export const getColumns = (columnsSort: Array<string>, hiddenColumnsAccessor: Array<string>, columns: Array<any>) => {
  if (columnsSort.length === 0) {
    return columns.filter((e) => !hiddenColumnsAccessor.find((h) => h === e.accessor));
  }
  return columnsSort
    .map((accessor: string) => {
      const element = columns.find((element) => element.accessor === accessor);
      return element as any;
    })
    .filter((e) => !hiddenColumnsAccessor.find((h) => h === e.accessor));
};

export const getQueryVariables = (state: State, entityType: EntityType, overrideFilters = {}) => {
  const { pageSize, filters, page, sortBy: stateSortBy } = (state as State)[entityType];

  const sortBy = stateSortBy[0] && stateSortBy[0].id;
  const sortOrder = stateSortBy[0] && stateSortBy[0].desc ? "DESC" : "ASC";
  const strapiFilters = matchFiltersWithStrapi({
    ...filters,
    ...overrideFilters,
  });
  return {
    pagination: {
      start: (page - 1) * pageSize,
      limit: pageSize,
    },
    ...(Object.keys(strapiFilters).length > 0 && {
      filter: JSON.stringify(strapiFilters),
    }),
    ...(sortBy && { sortBy: [sortBy], sortOrder: [sortOrder] }),
  };
};

const reducer = (state: State, action: ACTIONTYPE) => {
  switch (action.type) {
    case TableContextActions.GET_LOCAL_STORAGE_STATE:
      return setStateInLocalStorage(action.payload.state);
    case TableContextActions.UPDATE_ENTITYTYPE_ATTRIBUTE:
      return setStateInLocalStorage({
        ...state,
        [action.payload.entityType]: {
          ...state[action.payload.entityType],
          [action.payload.attribute]: action.payload.value,
        },
      });
    case TableContextActions.PERSIST_PAGE_SIZE:
      return setStateInLocalStorage({
        ...state,
        [action.payload.entityType]: {
          ...state[action.payload.entityType],
          pageSize: action.payload.pageSize,
          page: 1,
        },
      });
    case TableContextActions.PERSIST_SELECT_OBJECT:
      // eslint-disable-next-line no-case-declarations
      const selectedObjectsWithoutCurrentRow = state[action.payload.entityType].selectedObjects.filter((e) => e.id !== action.payload.obj.id);
      return setStateInLocalStorage({
        ...state,
        [action.payload.entityType]: {
          ...state[action.payload.entityType],
          selectedObjects: action.payload.checked ? [...selectedObjectsWithoutCurrentRow, action.payload.obj] : selectedObjectsWithoutCurrentRow,
        },
      });
    case TableContextActions.CLEAR_SELECTED_OBJECTS:
      return setStateInLocalStorage({
        ...state,
        [action.payload.entityType]: {
          ...state[action.payload.entityType],
          selectedObjects: [],
        },
      });
    case TableContextActions.PERSIST_FILTERS:
      // eslint-disable-next-line no-case-declarations
      const { newFilter } = action.payload;
      // eslint-disable-next-line no-case-declarations
      const keys = Object.keys(newFilter);

      // Remove filter if value is empty
      if (
        (keys[0] && !newFilter[keys[0]].filterValue) ||
        (keys[0] && newFilter[keys[0]].filterValue === "") ||
        (keys[0] &&
          newFilter[keys[0]].filterType === enumDatatableFilterType.between &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore */
          newFilter[keys[0]].filterValue?.start === undefined &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore */
          newFilter[keys[0]].filterValue?.end === undefined)
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [keys[0]]: removedFilter, ...newFilters } = state[action.payload.entityType].filters;

        return setStateInLocalStorage({
          ...state,
          [action.payload.entityType]: {
            ...state[action.payload.entityType],
            filters: newFilters,
            page: 1,
          },
        });
      }
      return setStateInLocalStorage({
        ...state,
        [action.payload.entityType]: {
          ...state[action.payload.entityType],
          filters: {
            ...state[action.payload.entityType].filters,
            ...newFilter,
          },
          page: 1,
        },
      });
    case TableContextActions.CLEAR_FILTERS:
      return setStateInLocalStorage({
        ...state,
        [action.payload.entityType]: {
          ...state[action.payload.entityType],
          filters: {},
        },
      });
    case TableContextActions.PERSIST_SORT_BY:
      // eslint-disable-next-line no-case-declarations
      const currentSortBy = state[action.payload.entityType].sortBy;
      if (JSON.stringify(currentSortBy[0]) !== JSON.stringify(action.payload.sortBy[0])) {
        return setStateInLocalStorage({
          ...state,
          [action.payload.entityType]: {
            ...state[action.payload.entityType],
            sortBy: action.payload.sortBy,
          },
        });
      }
      return state;
    case TableContextActions.HIDE_COLUMN:
      // eslint-disable-next-line no-case-declarations
      const { hiddenColumnsAccessor } = state[action.payload.entityType];
      return setStateInLocalStorage({
        ...state,
        [action.payload.entityType]: {
          ...state[action.payload.entityType],
          filters: initialState[action.payload.entityType].filters,
          hiddenColumnsAccessor: action.payload.hide
            ? [...hiddenColumnsAccessor.filter((e) => e !== action.payload.accessor), action.payload.accessor]
            : hiddenColumnsAccessor.filter((e) => e !== action.payload.accessor),
        },
      });
    case TableContextActions.FORCE_REFRESH:
      // eslint-disable-next-line no-case-declarations
      return setStateInLocalStorage({
        ...state,
        [action.payload.entityType]: {
          ...state[action.payload.entityType],
          forceRefetch: action.payload.refresh,
        },
      });
    case TableContextActions.SET_DATAS:
      // eslint-disable-next-line no-case-declarations
      return setStateInLocalStorage({
        ...state,
        [action.payload.entityType]: {
          ...state[action.payload.entityType],
          data: action.payload.data,
          ...(action.payload.totalCount && {
            totalCount: action.payload.totalCount,
          }),
        },
      });
    default:
      throw new Error();
  }
};

export const TableContext = createContext<[State, Dispatch<ACTIONTYPE>]>([
  initialState,
  (value: ACTIONTYPE) => {
    /* DO SOMETHING */
  },
]);

type Props = {
  children: ReactNode;
};

const TableProvider: FC<Props> = ({ children }) => {
  /* @ts-ignore */
  const [state, dispatch] = useReducer(reducer, initialState);

  const getLocalStorageState = () => {
    const json = localStorage.getItem(LOCAL_STORAGE_KEY);
    const localStorageState: State = json ? JSON.parse(json) : initialState;
    /* @ts-ignore */
    dispatch({
      type: TableContextActions.GET_LOCAL_STORAGE_STATE,
      payload: { state: localStorageState },
    });
  };

  useEffect(() => {
    getLocalStorageState();
  }, []);

  return <TableContext.Provider value={[state as Record<EntityType, InitProps<DatatableObject>>, dispatch]}>{children}</TableContext.Provider>;
};

export default TableProvider;
