import { FC, ReactElement, ReactFragment, ReactNode } from "react";

import { Box, SxProps, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

type Props = {
  title: JSX.Element | string | number | HTMLElement | null;
  className?: string;
  sx?: SxProps;
  useEllipsis?: boolean;
  placement?: "bottom" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start" | "top" | undefined;
  component?: "div" | "span";
  children?: ReactNode;
};

const useStyles = makeStyles({
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const DefaultTooltip: FC<Props> = ({ children, title, className, useEllipsis = false, placement = "bottom", component = "div", sx }) => {
  const classes = useStyles();
  return (
    <>
      {title && title !== "" ? (
        <Tooltip arrow placement={placement} title={title as ReactFragment} className={className}>
          {useEllipsis ? (
            <Box component={component} className={classes.ellipsis} sx={sx}>
              {children as ReactElement}
            </Box>
          ) : (
            (children as ReactElement)
          )}
        </Tooltip>
      ) : (
        children
      )}
    </>
  );
};

export default DefaultTooltip;
