import { useContext, forwardRef, ForwardedRef } from "react";
import check from "../../accessControl/check";
import { UserContext } from "src/providers/UserProvider";

const AccessControl = forwardRef(
  (
    {
      yes,
      no,
      resource,
    }: {
      yes: (ref: ForwardedRef<unknown>) => JSX.Element;
      no: (ref: ForwardedRef<unknown>) => JSX.Element;
      resource: string;
    },
    ref
  ) => {
    const { user } = useContext(UserContext);
    return check(user, resource) ? yes(ref) : no(ref);
  }
);

export default AccessControl;
