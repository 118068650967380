import { styled, Typography, alpha } from '@mui/material';

const UserBoxDescription2 = styled(Typography)(
  ({ theme }) => `
      color: ${alpha(theme.sidebar.menuItemColor as string, 0.6)};
  
      &.popoverTypo {
        color: ${theme.palette.secondary.light};
      }
  `
);

export default UserBoxDescription2;
