import { Box, styled } from '@mui/material';

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
  `
);

export default UserBoxText;
