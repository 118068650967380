export enum enumDatatableFilterType {
  dateRangePicker = "dateRangePicker",
  select = "select",
  multiselect = "multiselect",
  contain = "contain",
  between = "between",
}

export enum enumLanguage {
  fr = "fr",
}

export enum enumAssetType {
  video = "Video",
  document = "Document",
}

export enum enumRole {
  AUTHENTICATED = "Authenticated",
}

export enum enumWorkspace {
  DATA = "Données",
}

export enum enumFormState {
  NONE = "NONE",
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}
