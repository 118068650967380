import { styled, Select } from '@mui/material';

const CustomSelect = styled(Select)`
  &.MuiOutlinedInput-root {
    .MuiSelect-select {
      display: flex;

      .MuiTypography-root {
        font-weight: bold;
      }

      .MuiListItemIcon-root {
        min-width: 32px;
      }
    }
  }
`;

export default CustomSelect;
