import { useContext, FC } from "react";

import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import { Box, IconButton, Tooltip } from "@mui/material";
import HeaderWrapper from "src/components/Box/HeaderWrapper";
import Logo from "src/components/LogoSign/Logo";
import { SidebarContext } from "src/providers/SidebarProvider";

import HeaderUserbox from "./Userbox/HeaderUserbox";
import LanguageSwitcher from "../FrontLayout/Header/LanguageSwitcher";
import { useNavigate } from "react-router-dom";
import { INDEX_PATH } from "src/accessControl/route";

const Header: FC = () => {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const navigate = useNavigate();

  const navigateToHomePage = () => {
    navigate(INDEX_PATH);
  };

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box display="flex" alignItems="center">
        <Box
          component="span"
          sx={{
            width: "50px",
            mr: 1,
            display: { xs: "inline-block", lg: "none" },
          }}
          onClick={navigateToHomePage}
        >
          <Logo />
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        {/* <HeaderButtons /> */}
        <LanguageSwitcher />
        <HeaderUserbox />
        <Box
          component="span"
          sx={{
            display: { lg: "none", xs: "inline-block" },
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
};

export default Header;
