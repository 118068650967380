import { IconButton, styled } from '@mui/material';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
          width: ${theme.spacing(7)};
          height: ${theme.spacing(7)};
  `
);

export default IconButtonWrapper;
