import { useRef, useState, FC } from "react";

import { Box, List, ListItem, ListItemText, Popover, Tooltip, styled } from "@mui/material";
import frFlag from "country-flag-icons/3x2/FR.svg";
import { useTranslation } from "react-i18next";
import internationalization from "src/i18n/i18n";
import IconButtonWrapper from "src/components/IconButton/IconButtonWrapper";
import SectionHeading from "src/components/Typography/SectionHeading";
import { enumLanguage } from "src/types/enumeration";

const ImageWrapper = styled("img")(
  () => `
        width: 30px;
        margin: 3px;
`
);

const LanguageSwitcher: FC = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const getLanguage = i18n.language;

  const switchLanguage = ({ lng }: { lng: any }) => {
    internationalization.changeLanguage(lng);
  };
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title={t("acca_change_language") as string}>
        <IconButtonWrapper color="secondary" ref={ref} onClick={handleOpen}>
          {getLanguage === enumLanguage.fr && <ImageWrapper alt="Français" src={frFlag} />}
        </IconButtonWrapper>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            maxWidth: 240,
          }}
        >
          <SectionHeading variant="body2" color="text.primary">
            {t("acca_language_switcher")}
          </SectionHeading>
          <List
            sx={{
              p: 2,
            }}
            component="nav"
          >
            <ListItem
              className={getLanguage === enumLanguage.fr ? "active" : ""}
              button
              onClick={() => {
                switchLanguage({ lng: enumLanguage.fr });
                handleClose();
              }}
            >
              <ImageWrapper alt="Français" src={frFlag} />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary="Français"
              />
            </ListItem>
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default LanguageSwitcher;
