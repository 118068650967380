import { Suspense, lazy, LazyExoticComponent } from "react";
import { COMING_SOON_PATH, MAINTENANCE_PATH, S_500_PATH } from "src/accessControl/route";

import SuspenseLoader from "src/components/SuspenseLoader/SuspenseLoader";

const Loader = (Component: JSX.IntrinsicAttributes | LazyExoticComponent<() => JSX.Element>) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      {/* @ts-ignore */}
      <Component {...props} />
    </Suspense>
  );

// Status
const Status404 = Loader(lazy(() => import("src/content/pages/Status/Status404/Status404") as any));
const Status500 = Loader(lazy(() => import("src/content/pages/Status/Status500/Status500") as any));
const StatusComingSoon = Loader(lazy(() => import("src/content/pages/Status/ComingSoon/StatusComingSoon") as any));
const StatusMaintenance = Loader(lazy(() => import("src/content/pages/Status/Maintenance/StatusMaintenance") as any));

const statusRoutes = [
  {
    path: "",
    element: <Status404 />,
  },
  {
    path: S_500_PATH,
    element: <Status500 />,
  },
  {
    path: MAINTENANCE_PATH,
    element: <StatusMaintenance />,
  },
  {
    path: COMING_SOON_PATH,
    element: <StatusComingSoon />,
  },
];

export default statusRoutes;
