import { styled } from "@mui/material";

const Svg = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M3233 4903 c-7 -3 -13 -12 -13 -22 0 -10 104 -145 231 -301 222 -272
 240 -299 197 -300 -19 0 -4 -38 41 -105 l50 -73 -102 -7 c-191 -13 -196 -12
 -271 23 l-69 32 -51 -29 -51 -29 -30 50 c-41 68 -114 134 -165 149 -33 10 -54
 10 -98 1 -142 -30 -227 -153 -221 -322 2 -61 -2 -76 -29 -129 -36 -65 -36 -65
 42 -76 35 -6 52 -3 71 9 31 21 33 20 37 -6 2 -19 9 -23 34 -21 23 2 41 -7 75
 -37 24 -22 50 -40 57 -40 7 0 35 12 63 26 28 14 54 23 57 19 3 -3 -6 -41 -21
 -85 -15 -44 -27 -92 -27 -107 l1 -28 19 23 c11 13 28 36 39 50 27 38 33 25 16
 -38 -19 -71 -45 -265 -45 -338 0 -32 -10 -84 -22 -117 -14 -40 -21 -87 -22
 -140 -1 -44 -6 -89 -12 -100 -6 -11 -28 -73 -48 -139 -23 -73 -46 -127 -60
 -141 -13 -13 -26 -35 -30 -49 -3 -13 -18 -37 -34 -52 -26 -25 -33 -27 -132
 -29 -58 -1 -117 -5 -132 -9 -27 -8 -28 -7 -28 28 0 30 -6 40 -32 56 -19 12
 -48 20 -68 19 -60 -3 -67 0 -94 40 -37 52 -79 71 -160 71 -75 0 -144 -21 -198
 -59 -37 -26 -43 -35 -94 -145 -43 -94 -82 -131 -156 -147 -94 -20 -198 -15
 -313 16 -72 19 -134 28 -220 32 -142 6 -220 -8 -355 -62 -99 -40 -119 -39
 -150 5 -42 59 -52 109 -59 285 -6 148 -10 175 -29 213 -12 23 -26 42 -31 42
 -9 0 -36 -136 -52 -260 -18 -143 -7 -184 81 -315 88 -131 92 -142 79 -243 -8
 -61 -8 -102 -1 -137 6 -27 27 -126 46 -220 38 -181 38 -196 1 -281 -24 -54
 -66 -93 -129 -119 -35 -15 -38 -19 -32 -48 3 -18 9 -66 12 -107 6 -66 5 -74
 -8 -63 -8 7 -34 20 -59 28 l-43 16 26 -28 c28 -30 68 -92 68 -106 0 -5 -18 5
 -39 22 -21 17 -59 40 -82 51 -46 21 -134 27 -144 10 -3 -6 3 -10 15 -10 33 0
 112 -45 153 -88 l39 -40 -53 -28 c-100 -55 -78 -60 331 -74 426 -14 555 -32
 644 -92 57 -37 91 -89 77 -117 -8 -14 -7 -22 3 -30 14 -12 1104 -232 1361
 -275 325 -55 474 -53 475 7 0 4 -19 28 -41 52 -23 25 -39 48 -36 52 4 3 25 1
 49 -5 23 -6 44 -9 46 -7 4 4 -52 41 -73 49 -22 8 6 17 56 18 53 2 54 2 24 14
 -52 22 -113 27 -161 14 -42 -12 -46 -11 -69 10 -14 13 -23 26 -20 29 3 3 90 6
 193 7 457 5 916 97 1194 241 85 44 168 116 168 147 0 8 -11 27 -24 43 -24 29
 -24 29 -7 69 21 45 39 62 101 98 l45 26 -45 -6 c-60 -9 -115 -29 -152 -55 -28
 -20 -30 -21 -25 -3 12 39 26 109 22 112 -2 2 -17 -15 -34 -39 -29 -39 -31 -40
 -31 -17 0 35 -29 198 -38 214 -4 7 -5 -29 -4 -79 4 -102 -6 -107 -32 -18 -17
 59 -21 183 -7 243 13 57 -3 37 -40 -50 -30 -69 -33 -87 -33 -172 -1 -60 4
 -104 12 -118 21 -37 9 -35 -22 4 -20 25 -33 59 -45 124 -15 74 -24 96 -55 131
 -20 24 -41 61 -47 84 -6 22 -22 65 -35 96 -25 58 -30 89 -16 89 5 0 12 117 15
 260 5 228 4 264 -10 288 -16 28 -15 30 24 88 22 32 52 82 66 109 24 47 32 54
 161 125 104 57 135 78 130 90 -16 36 -8 54 36 85 29 20 48 43 54 63 7 25 22
 39 68 64 37 20 58 37 58 49 0 10 -33 62 -73 116 -40 54 -95 130 -122 168 -27
 39 -64 86 -81 105 -32 36 -76 115 -89 160 -3 14 -54 125 -112 247 l-106 221
 -88 41 c-102 47 -87 30 -565 613 -157 191 -288 349 -292 351 -4 3 -13 2 -19 0z
 m122 -2343 c0 -66 5 -92 20 -118 24 -40 98 -77 199 -99 l72 -16 -17 -106 c-34
 -208 -34 -243 0 -376 17 -66 31 -134 31 -152 0 -17 9 -48 21 -67 34 -58 56
 -129 51 -167 -4 -34 1 -40 155 -187 l159 -151 -1 -47 -1 -47 -64 -34 c-61 -33
 -65 -33 -78 -16 -13 17 -11 85 5 131 3 9 -6 0 -21 -20 -14 -20 -26 -42 -26
 -48 0 -6 -4 -8 -10 -5 -5 3 -10 30 -11 58 l-1 52 -14 -34 c-9 -19 -18 -57 -21
 -85 -6 -47 -16 -65 -35 -66 -5 0 -8 20 -8 44 0 66 -24 181 -58 271 -28 79 -68
 162 -57 119 24 -91 35 -174 34 -269 -1 -163 -6 -170 -52 -80 -24 45 -64 101
 -104 142 -62 66 -63 66 -33 20 51 -77 79 -147 105 -261 l6 -29 -36 31 c-40 35
 -114 76 -122 68 -5 -5 32 -57 101 -142 l26 -32 -32 -6 c-23 -4 -34 -2 -44 11
 -28 36 -55 103 -68 165 l-13 63 -8 -49 c-8 -48 -2 -140 11 -184 6 -20 3 -22
 -29 -22 -29 0 -36 4 -41 26 -4 14 -5 33 -2 42 17 64 17 76 3 63 -8 -6 -20 -25
 -27 -41 -17 -42 -30 -28 -31 33 l-1 52 -15 -35 c-9 -19 -17 -61 -17 -92 -1
 -56 -2 -58 -28 -58 -25 0 -26 3 -22 32 3 18 1 47 -4 63 -9 29 -10 28 -27 -32
 -10 -35 -22 -63 -26 -63 -28 0 -17 105 18 174 l15 31 -32 -30 c-46 -44 -72
 -82 -91 -139 -16 -45 -18 -48 -18 -20 -1 37 24 125 51 179 11 21 46 66 77 98
 l56 60 -45 -23 c-73 -36 -136 -88 -168 -134 l-29 -44 -11 82 c-21 159 -84 294
 -187 404 l-54 57 40 -83 c47 -96 84 -234 94 -347 l6 -80 -35 70 c-34 70 -122
 176 -176 215 -26 18 -25 15 16 -47 24 -37 54 -92 65 -123 25 -66 54 -199 45
 -207 -4 -4 -23 9 -44 29 -49 48 -123 92 -206 123 -88 33 -91 32 -22 -10 80
 -48 145 -104 185 -160 l34 -47 -34 7 c-19 4 -34 8 -34 9 -1 0 -13 18 -28 39
 -29 38 -55 51 -42 20 4 -10 9 -24 12 -30 3 -8 -3 -13 -16 -13 -12 0 -24 8 -27
 18 -3 9 -14 35 -24 57 -16 34 -19 36 -19 15 -1 -24 -2 -23 -30 10 -26 31 -30
 33 -36 17 -4 -10 -5 -36 -2 -58 5 -38 4 -41 -16 -35 -12 3 -27 6 -33 6 -7 0
 -15 17 -19 38 -4 20 -16 54 -28 76 l-22 39 -5 -67 c-3 -36 -9 -67 -14 -69 -5
 -1 -15 18 -21 43 -17 64 -39 102 -80 139 -51 44 -59 39 -24 -17 16 -27 32 -66
 35 -88 l6 -39 -27 33 c-37 45 -47 40 -28 -14 8 -25 13 -47 11 -50 -13 -12 -39
 12 -60 54 -21 43 -23 59 -21 187 1 77 6 179 12 227 10 87 11 90 99 251 21 39
 22 53 21 192 -1 142 0 153 25 208 18 40 26 75 26 115 0 31 4 62 8 68 4 6 26
 16 50 21 39 10 43 10 36 -5 -4 -10 -9 -30 -12 -46 l-5 -30 28 22 c33 26 71 28
 178 7 43 -8 105 -15 137 -15 53 0 61 -3 78 -27 32 -47 42 -92 42 -195 0 -95
 15 -158 39 -158 5 0 14 -13 21 -30 12 -29 37 -57 44 -49 2 2 -2 45 -9 94 -16
 107 -45 382 -45 425 0 29 2 30 44 30 l44 0 139 156 c76 85 151 171 168 190 21
 26 36 34 55 32 25 -3 25 -5 25 -88z m-1903 -870 c86 -44 176 -87 200 -95 24
 -9 68 -29 99 -45 31 -16 80 -36 110 -43 l54 -14 12 -79 c6 -44 12 -136 12
 -206 1 -124 2 -129 31 -175 35 -54 39 -93 12 -93 -13 0 -25 14 -37 43 l-19 42
 -3 -41 c-4 -38 -6 -41 -30 -36 -25 5 -25 6 -21 80 l5 75 -24 -41 c-14 -23 -29
 -39 -34 -36 -5 3 -9 26 -10 52 l-1 47 -15 -34 c-8 -19 -16 -57 -18 -85 -4 -51
 -4 -51 -36 -48 -18 1 -34 4 -36 6 -2 1 6 21 17 44 33 67 26 82 -17 40 -34 -32
 -73 -58 -87 -58 -2 0 11 23 29 51 50 73 67 151 46 204 -7 17 -10 13 -21 -25
 -16 -60 -43 -111 -71 -135 l-22 -20 8 20 c4 11 10 74 12 140 5 121 -8 219 -38
 293 -13 31 -14 25 -15 -80 -1 -129 -13 -197 -58 -318 l-31 -85 -6 90 c-4 56
 -17 120 -33 169 -37 108 -48 125 -39 56 18 -125 2 -272 -38 -365 l-14 -30 -3
 50 c-4 80 -13 86 -33 25 l-19 -55 5 75 4 75 -19 -25 c-11 -14 -28 -47 -38 -74
 l-19 -49 -11 46 c-16 62 -15 181 2 245 17 68 0 55 -42 -31 -41 -82 -55 -149
 -47 -219 l6 -58 -31 0 c-25 0 -30 4 -30 24 0 32 -20 86 -32 86 -6 0 -7 -21 -3
 -55 5 -34 4 -55 -3 -55 -14 0 -59 62 -82 112 l-20 43 0 -41 c0 -23 7 -60 15
 -84 19 -54 3 -50 -43 10 -31 40 -33 47 -27 94 14 114 26 146 85 219 32 40 79
 87 104 105 52 37 73 74 104 177 30 99 87 171 137 174 6 1 81 -35 167 -79z
 m-679 -587 c24 -104 29 -163 14 -168 -6 -2 -31 -6 -54 -9 l-43 -6 0 86 c0 78
 3 90 28 125 15 21 30 39 33 39 4 0 14 -30 22 -67z"
      />
      <path
        d="M2377 4837 c-9 -58 -57 -117 -180 -220 -105 -89 -118 -97 -157 -97
 -33 0 -50 7 -83 35 -40 35 -80 45 -93 24 -3 -6 -20 -19 -38 -30 l-31 -19 63 0
 c49 0 69 -5 92 -22 17 -12 30 -25 30 -29 0 -5 -19 -13 -42 -19 -24 -6 -58 -16
 -75 -23 -28 -9 -40 -8 -78 11 -36 17 -45 19 -45 7 0 -28 53 -43 189 -50 119
 -7 141 -11 200 -38 85 -39 160 -46 185 -18 18 20 18 21 -18 42 -20 12 -41 29
 -46 38 -10 20 8 62 44 101 13 14 35 43 48 65 14 22 33 47 43 55 31 26 78 160
 55 160 -5 0 -6 7 -3 17 4 11 3 14 -5 9 -7 -4 -12 -2 -12 5 0 7 -3 9 -7 6 -3
 -4 -11 1 -18 10 -10 14 -13 11 -18 -20z"
      />
      <path
        d="M1272 4607 c-7 -19 -40 -68 -74 -108 -35 -41 -77 -100 -93 -132 l-31
 -57 -16 33 c-9 18 -21 60 -28 92 -11 52 -38 95 -59 95 -42 -1 -52 -106 -13
 -154 12 -16 22 -38 22 -48 0 -17 -4 -16 -29 11 -16 17 -35 31 -43 31 -7 0 -32
 -14 -56 -30 l-44 -29 63 -3 c62 -3 63 -4 94 -48 52 -76 213 -148 266 -119 29
 15 22 53 -11 57 -60 8 -67 75 -15 152 19 29 35 60 35 70 0 11 5 22 10 25 6 3
 10 16 10 28 0 12 8 45 18 72 11 28 16 60 13 74 -6 22 -7 21 -19 -12z"
      />
      <path
        d="M1590 4027 c-45 -56 -92 -119 -104 -140 -17 -30 -26 -37 -44 -34 -19
 4 -29 -4 -53 -42 -20 -31 -28 -54 -25 -72 4 -21 0 -28 -17 -33 -39 -10 -70 -6
 -89 11 -10 9 -39 20 -66 24 -38 6 -57 4 -99 -14 -29 -12 -53 -25 -53 -28 0 -3
 35 -9 78 -13 67 -5 94 -14 212 -71 74 -35 152 -67 173 -71 33 -5 183 7 255 22
 48 10 5 64 -51 64 -15 0 -41 7 -57 15 -35 18 -39 51 -15 108 8 20 17 57 19 82
 3 26 11 55 19 64 22 25 39 100 41 180 1 38 -2 44 -21 47 -17 3 -38 -18 -103
 -99z"
      />
    </g>
  </svg>
);

const LogoACCA = styled(Svg)`
  width: 100%;
`;

export default LogoACCA;
