import { IconButton, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { makeStyles } from "@mui/styles";
import { HEIGHT_LOGO, WIDTH_LOGO } from "src/constants/constants";
import { PureLightTheme } from "src/theme/PureLightTheme";
import DefaultTooltip from "../Tooltip/DefaultTooltip";

const useStyles = makeStyles({
  image: {
    marginRight: PureLightTheme.spacing(2),
    width: WIDTH_LOGO,
    height: HEIGHT_LOGO,
    padding: PureLightTheme.spacing(1),
  },
  sectionDesktop: {
    display: "none",
    [PureLightTheme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      gap: "5px",
    },
  },
});

type Props = {
  otherButtons: Array<{
    text: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    action?: () => void;
  }>;
};

export default function EndSectionDesktopHeader({ otherButtons }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.sectionDesktop}>
      {otherButtons.map((button, index) => {
        const Icon = button.icon;
        return (
          <DefaultTooltip title={button.text} key={index}>
            <IconButton edge={index === otherButtons.length - 1 ? "end" : false} aria-label="exit app" aria-haspopup="true" color="default" onClick={button.action}>
              <Icon />
            </IconButton>
          </DefaultTooltip>
        );
      })}
    </div>
  );
}
