import { Card, styled } from "@mui/material";
import { FC } from "react";

const FooterWrapper = styled(Card)(
  ({ theme }) => `
        min-height: 50px;
        border-radius: 0;
        margin-top: ${theme.spacing(4)};
        overflow: visible;
`
);

const Footer: FC = () => {
  return <FooterWrapper className="footer-wrapper"></FooterWrapper>;
};

export default Footer;
