import { useContext, useRef, useState, FC } from "react";

import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import {  Box, Button, Popover } from "@mui/material";
import { useTranslation } from "react-i18next";
import MenuUserBox from "src/components/Box/MenuUserBox";
import UserBoxText from "src/components/Box/UserBoxText";
import UserBoxButton from "src/components/Button/UserBoxButton";
import UserBoxDescription from "src/components/Typography/UserBoxDescription";
import UserBoxLabel from "src/components/Typography/UserBoxLabel";
import { UserContext } from "src/providers/UserProvider";

const HeaderUserbox: FC = () => {
  const { t } = useTranslation();
  const { logout, user } = useContext(UserContext);

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const onLogout = () => {
    logout({});
  };

  const username = user?.username;

  return (
    <>
      <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
        <Box
          component="span"
          sx={{
            display: { xs: "none", md: "inline-block" },
          }}
        >
          <UserBoxText>
            <UserBoxLabel variant="body1">{username}</UserBoxLabel>
            <UserBoxDescription variant="body2">{/* {artisan?.jobtitle} */}</UserBoxDescription>
          </UserBoxText>
        </Box>
        <Box
          component="span"
          sx={{
            display: { xs: "none", sm: "inline-block" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} ml={1}>
            <ExpandMoreTwoToneIcon color="secondary" />
          </Box>
        </Box>
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 270,
            justifyContent: "center",
            alignItems: "center",
          }}
          display="flex"
        >
          <UserBoxText>
            <UserBoxLabel variant="body1">{username}</UserBoxLabel>
          </UserBoxText>
        </MenuUserBox>
        <Box m={1}>
          <Button color="primary" fullWidth onClick={onLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1,
              }}
            />
            {t("acca_sign_out")}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default HeaderUserbox;
