import { useContext } from "react";
import { getToken } from "src/auth/session";
import { UserContext } from "src/providers/UserProvider";

const useAuth = (): { isAuthenticated: boolean } => {
  const { user } = useContext(UserContext);
  const token = getToken() && user;

  const isAuthenticated = !!token && !!user;
  return { isAuthenticated };
};

export default useAuth;
