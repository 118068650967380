import { FC, useEffect } from "react";
import { useNavigate } from "react-router";
import { DASHBOARD_PATH } from "src/accessControl/route";
import useAuth from "src/hooks/useAuth";

type Props = { children: JSX.Element };

const PublicRoute: FC<Props> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(DASHBOARD_PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isAuthenticated ? null : children;
};

export default PublicRoute;
