import { useContext, FC, useState } from "react";

import { Box, Drawer, useTheme } from "@mui/material";
import SidebarWrapper from "src/components/Box/SidebarWrapper";
import TopSection from "src/components/Box/TopSection";
import Logo from "src/components/LogoSign/Logo";
import Scrollbar from "src/components/Scrollbar/Scrollbar";
import { SidebarContext } from "src/providers/SidebarProvider";

import SidebarMenu from "./SidebarMenu/SidebarMenu";
import SidebarTopSection from "./SidebarTopSection/SidebarTopSection";
import { enumWorkspace } from "src/types/enumeration";
import { useNavigate } from "react-router-dom";
import { INDEX_PATH } from "src/accessControl/route";

const Sidebar: FC = () => {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const navigate = useNavigate();
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const [workspace, setWorkspace] = useState<enumWorkspace>(enumWorkspace?.DATA);

  const navigateToHomePage = () => {
    navigate(INDEX_PATH);
  };

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: "none",
            lg: "inline-block",
          },
          position: "fixed",
          left: 0,
          top: 0,
        }}
      >
        <Scrollbar>
          <TopSection>
            <Box
              display="flex"
              sx={{
                mx: 2,
                mt: 2,
                mb: 3,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: 100, cursor: "pointer" }} onClick={navigateToHomePage}>
                <Logo />
              </Box>
            </Box>
            <SidebarTopSection workspace={workspace} setWorkspace={setWorkspace} />
          </TopSection>
          <SidebarMenu workspace={workspace} />
        </Scrollbar>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Scrollbar>
            <TopSection>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  sx={{
                    width: 52,
                    ml: 1,
                    mt: 1,
                    mb: 3,
                  }}
                  onClick={navigateToHomePage}
                >
                  <Logo />
                </Box>
              </Box>
              <SidebarTopSection workspace={workspace} setWorkspace={setWorkspace} />
            </TopSection>
            <SidebarMenu workspace={workspace} />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
};

export default Sidebar;
