import { FC, ReactNode, useState, useContext } from 'react';

import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { Button, Badge, Collapse, ListItem } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import TooltipWrapper from 'src/components/Tooltip/TooltipWrapper';
import { SidebarContext } from 'src/providers/SidebarProvider';

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  badgeTooltip?: string;
  openParent?: boolean;
  name: string;
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  badgeTooltip,
  openParent = false,
  name
}) => {
  const [menuToggle, setMenuToggle] = useState<boolean>(openParent);
  const { t } = useTranslation();
  const { closeSidebar } = useContext(SidebarContext);

  const toggleMenu = (): void => {
    setMenuToggle((Open) => !Open);
  };

  const renderBadgeChildren = () =>
    badge === '' ? (
      <Badge color="primary" variant="dot" />
    ) : (
      <Badge badgeContent={badge} />
    );

  const renderBadge = () =>
    badgeTooltip ? (
      <TooltipWrapper title={badgeTooltip} arrow placement="right">
        {renderBadgeChildren()}
      </TooltipWrapper>
    ) : (
      renderBadgeChildren()
    );

  if (children) {
    return (
      <ListItem component="div" className="Mui-children" key={name}>
        <Button
          className={clsx({ 'Mui-active': menuToggle })}
          startIcon={Icon && <Icon />}
          endIcon={
            menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
          }
          onClick={toggleMenu}
        >
          {renderBadge()}
          {t(name)}
        </Button>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem component="div" key={name}>
      <Button
        disableRipple
        // activeClassName="Mui-active"
        component={RouterLink}
        onClick={closeSidebar}
        to={link as any}
        startIcon={Icon && <Icon />}
      >
        {t(name)}
        {renderBadge()}
      </Button>
    </ListItem>
  );
};

export default SidebarMenuItem;
