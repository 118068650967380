const fr = {
  acca_sign_in: "Connexion",
  acc_auth0_sign_in_fill: "Remplissez les champs ci-dessous pour vous connecter à votre compte.",
  acca_email_address: "Email",
  acca_password: "Mot de passe",
  acca_register_email_required: "Le champ email est requis",
  acca_register_password_required: "Le champ mot de passe est requis",
  acca_change_language: "Changer de langue",
  acca_language_switcher: "Sélecteur de langue",
  acca_sign_out: "Deconnexion",
  acca_workspace_label: "Espace de travail",
  acca_data_label: "Données",
  acca_emails_label: "Emails",
  acca_members_label: "Membres",
  acca_groups_label: "Groupes",
  acca_contacts_label: "Contacts",
  acca_partners_label: "Sponsors",
  acca_events_label: "Évenements",
  acca_assets_label: "Assets",
  acca_welcome: "Bienvenue",
  acca_welcome_date: "Aujourd'hui nous somme le",
  acca_home: "Accueil",
  acca_office: "Bureau",
  acca_territory: "Territoire",
  acca_daycare: "Garderie",
  acca_contacts: "Contacts",
  acca_partners: "Partenaires",
  acca_photos: "Photos",
  acca_events: "Évenements",
  acca_account: "Mon compte",
  acca_dashboard: "Dashboard",
  acca_logout: "Deconnexion",
  acca_photos_title: "Photos",
  acca_menu_tooltip: "Menu",
  acca_from: "Du",
  acca_to: "au",
  acca_home_incoming_events: "Évènements à venir",
  acca_download_button_title: "Cliquez ici pour afficher",
  acca_edit: "Modifier",
  acca_delete: "Supprimer",
  acca_cancel: "Annuler",
  acca_valid: "Valider",
  acca_add: "Ajouter",
  acca_deletion_success: "Le contenu a été supprimé avec succès",
  acca_edition_success: "Le contenu a été édité avec succès",
  acca_member: "Membre",
  acca_asset: "Asset",
  acca_title_label: "Titre",
  acca_title_placeholder: "Titre",
  acca_role_label: "Role",
  acca_role_placeholder: "Role",
  acca_lastname_label: "Nom",
  acca_lastname_placeholder: "Nom",
  acca_firstname_label: "Prénom",
  acca_firstname_placeholder: "Prénom",
  acca_email_label: "Email",
  acca_email_placeholder: "Email",
  acca_phone_label: "Téléphone",
  acca_phone_placeholder: "Téléphone",
  acca_phone_regex_message: "Numéro de téléphone invalide",
  acca_email_regex_message: "Email invalide",
  acc_line_per_page: "Ligne(s) par page",
  acca_email_object: "Objet",
  acca_email_object_placeholder: "Objet",
  acca_no_result: "Aucun résultat",
  acca_email_content: "Contenu",
  acca_email_content_placeholder: "Contenu",
  acca_email_contactGroup: "Groupe de contact",
  acca_email_contactGroup_placeholder: "Groupe de contact",
  acca_createdAt: "Créé le",
  acca_updatedAt: "Modifié le",
  acca_loading: "Chargement",
  acca_no_options: "Pas d'options",
  acca_asc: "ASC",
  acca_desc: "DESC",
  acca_dataTable_selecteds: "Sélectionnés",
  acca_dataTable_selected: "Sélectionné",
  acca_items: "Élements",
  acca_item: "Élement",
  acca_member_lastname: "Nom",
  acca_member_firstname: "Prénom",
  acca_member_phone: "Téléphone",
  acca_member_role: "Rôle",
  acca_member_email: "Email",
  acca_contactGroup_name: "Nom",
  acca_contactGroup_name_placeholder: "Nom",
  acca_contact_firstname: "Prénom",
  acca_contact_firstname_placeholder: "Prénom",
  acca_contact_lastname: "Nom",
  acca_contact_lastname_placeholder: "Nom",
  acca_contact_phone: "Téléphone",
  acca_contact_phone_placeholder: "Téléphone",
  acca_contact_email: "Email",
  acca_contact_email_placeholder: "Email",
  acca_contact_contactGroup: "Groupes",
  acca_contact_contactGroup_placeholder: "Groupes",
  acca_sponsor_name: "Name",
  acca_sponsor_name_placeholder: "Name",
  acca_sponsor_media: "Media",
  acca_sponsor_url: "Url",
  acca_sponsor_url_placeholder: "Url",
  acca_event_title: "Titre",
  acca_event_title_placeholder: "Titre",
  acca_event_startDate: "Date de début",
  acca_event_startDate_placeholder: "Date de début",
  acca_event_endDate: "Date de fin",
  acca_event_endDate_placeholder: "Date de fin",
  acca_event_description: "Description",
  acca_event_description_placeholder: "Description",
  acca_event_media: "Media",
  acca_asset_title: "Titre",
  acca_asset_media: "Media",
  acca_photo: "Ajouter une nouvelle photo",
  acca_send_new_email: "Envoyer un nouveau email",
  acca_actions: "Actions",
  acca_event: "Évenement",
  acca_register_valid_email_adress: "Invalid email format",
  acca_contact_contactGroups: "Groupe de contact",
  acca_contact_contactGroups_placeholder: "Groupe de contact",
  acca_contactGroup_contacts: "Contacts",
  acca_contactGroup_contacts_placeholder: "Contacts",
  acca_sendedAt: "Envoyé le",
  see_event_details: "Voir les détails de l'évènement",
  acca_email: "Email",
  acca_contactGroup: "Groupe",
  acca_contact: "Contact",
  acca_sponsor: "Sponsor",
  acca_login: "Connexion",
  acca_homepage: "Retourner sur la page d'accueil",
  acca_status_page_doesnt_exist_subtitle: "Cliquer sur le bouton ci-dessous pour retourner sur le site",
  acca_status_page_doesnt_exist: "La page semble ne pas exister",
};

export default fr;
