import { enumRole } from "src/types/enumeration";

/* Common */
export const INDEX_PAGE_RESSOURCE = "INDEX_PAGE_RESSOURCE";
export const LOGIN_PAGE_RESSOURCE = "LOGIN_PAGE_RESSOURCE";

export const STATUS_RESSOURCE = "STATUS_RESSOURCE";
export const STATUS_500_RESSOURCE = "STATUS_500_RESSOURCE";
export const STATUS_MAINTENANCE_RESSOURCE = "STATUS_MAINTENANCE_RESSOURCE";
export const STATUS_COMING_SOON_RESSOURCE = "STATUS_COMING_SOON_RESSOURCE";
export const OFFICE_RESSOURCE = "OFFICE_RESSOURCE";
export const PARTNERS_RESSOURCE = "PARTNERS_RESSOURCE";
export const EVENTS_RESSOURCE = "EVENTS_RESSOURCE";
export const PHOTOS_RESSOURCE = "PHOTOS_RESSOURCE";
export const TERRITORY_RESSOURCE = "TERRITORY_RESSOURCE";
export const DAYCARE_RESSOURCE = "DAYCARE_RESSOURCE";

export const DASHBOARD_RESSOURCE = "DASHBOARD_RESSOURCE";
export const EMAILS_RESSOURCE = "EMAILS_RESSOURCE";
export const MEMBERS_RESSOURCE = "MEMBERS_RESSOURCE";
export const CONTACT_GROUPS_RESSOURCE = "CONTACT_GROUPS_RESSOURCE";
export const CONTACTS_RESSOURCE = "CONTACTS_RESSOURCE";
export const SPONSORS_RESSOURCE = "SPONSORS_RESSOURCE";
export const AUTHENTICATED_EVENTS_RESSOURCE = "AUTHENTICATED_EVENTS_RESSOURCE";
export const ASSETS_RESSOURCE = "ASSETS_RESSOURCE";

const commonRessources = [
  INDEX_PAGE_RESSOURCE,
  LOGIN_PAGE_RESSOURCE,
  STATUS_RESSOURCE,
  STATUS_500_RESSOURCE,
  STATUS_MAINTENANCE_RESSOURCE,
  STATUS_COMING_SOON_RESSOURCE,
  OFFICE_RESSOURCE,
  EVENTS_RESSOURCE,
  PHOTOS_RESSOURCE,
  TERRITORY_RESSOURCE,
  DAYCARE_RESSOURCE,
];

const authenticatedUserRules = [
  ...commonRessources,
  DASHBOARD_RESSOURCE,
  EMAILS_RESSOURCE,
  MEMBERS_RESSOURCE,
  CONTACT_GROUPS_RESSOURCE,
  CONTACTS_RESSOURCE,
  SPONSORS_RESSOURCE,
  AUTHENTICATED_EVENTS_RESSOURCE,
  ASSETS_RESSOURCE,
];

const rules: {
  [x: string]: string[];
} = {
  [enumRole.AUTHENTICATED]: authenticatedUserRules,
};

export default rules;
