export const SPACING = 3;
export const MARGIN = "none";
export const SIZE = "small";
export const URL_REGEX =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
export const URL_REGEX_MESSAGE = "Invalid URL format";

export const PASSWORD_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

export const PASSWORD_REGEX_MESSAGE = "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character";

export const OBJECT_IS_NOT_DEFINED = "OBJECT_IS_NOT_DEFINED";

export const PHONE_REGEX = /^(0|\\+33)[1-9]([-. ]?[0-9]{2}){4}$/;
export const PHONE_REGEX_MESSAGE = "Invalid phone number";

export const WIDTH_LOGO = 80;
export const HEIGHT_LOGO = 80;
