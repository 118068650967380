import { styled, Typography } from '@mui/material';

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        display: block;
`
);

export default UserBoxLabel;
