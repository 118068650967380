import { FC, ReactNode, useContext, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import AccessControl from "src/components/AccessControl/AccessControl";
import useRefreshToken from "src/hooks/useRefreshToken";
import { getPathnameRessource, LOGIN_PATH } from "src/accessControl/route";
import useAuth from "src/hooks/useAuth";
import { UserContext } from "src/providers/UserProvider";
import Status404 from "src/content/pages/Status/Status404/Status404";

type Props = { children: ReactNode };

const PrivateRoute: FC<Props> = ({ children }) => {
  const { logout } = useContext(UserContext);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const resource = getPathnameRessource(location?.pathname);

  useEffect(() => {
    if (!isAuthenticated) {
      logout({
        callback: () =>
          navigate(LOGIN_PATH, { state: { path: location.pathname } }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useRefreshToken();

  return isAuthenticated ? (
    <AccessControl
      resource={resource}
      yes={() => <>{children}</>}
      no={() => <Status404 />}
    />
  ) : null;
};

export default PrivateRoute;
