import BackupTableTwoToneIcon from "@mui/icons-material/BackupTableTwoTone";
import { enumWorkspace } from "src/types/enumeration";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import i18next from "i18next";
import MailIcon from "@mui/icons-material/Mail";
import GroupsIcon from "@mui/icons-material/Groups";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import EngineeringIcon from "@mui/icons-material/Engineering";
import EventIcon from "@mui/icons-material/Event";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import { ASSETS_PATH, AUTHENTICATED_EVENTS_PATH, CONTACTS_PATH, CONTACT_GROUPS_PATH, EMAILS_PATH, MEMBERS_PATH, SPONSORS_PATH } from "src/accessControl/route";

export interface MenuItem {
  link?: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string };
  badge?: string;
  badgeTooltip?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string };
  heading: string;
  redirectTo: string | undefined;
}

export const workspaceOptions = [
  {
    icon: BackupTableTwoToneIcon,
    value: enumWorkspace.DATA,
    text: i18next.t("acca_data_label"),
  },
];

export const getMenuItems = (workspace: enumWorkspace) => {
  switch (workspace) {
    case enumWorkspace.DATA:
      const option = workspaceOptions?.find((e) => e.value === enumWorkspace.DATA);
      return {
        heading: option?.text,
        icon: option?.icon,
        items: [
          {
            name: i18next.t("acca_emails_label"),
            icon: MailIcon,
            link: EMAILS_PATH,
          },
          {
            name: i18next.t("acca_members_label"),
            icon: EngineeringIcon,
            link: MEMBERS_PATH,
          },
          {
            name: i18next.t("acca_groups_label"),
            icon: GroupsIcon,
            link: CONTACT_GROUPS_PATH,
          },
          {
            name: i18next.t("acca_contacts_label"),
            icon: ContactMailIcon,
            link: CONTACTS_PATH,
          },
          {
            name: i18next.t("acca_partners_label"),
            icon: WorkspacesIcon,
            link: SPONSORS_PATH,
          },
          {
            name: i18next.t("acca_events_label"),
            icon: EventIcon,
            link: AUTHENTICATED_EVENTS_PATH,
          },
          {
            name: i18next.t("acca_assets_label"),
            icon: WebAssetIcon,
            link: ASSETS_PATH,
          },
        ],
        redirectTo: undefined,
      } as MenuItems;
  }
};
