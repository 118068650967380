import { ListSubheader, List, useTheme } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, matchPath } from "react-router-dom";
import MenuWrapper from "src/components/Box/MenuWrapper";
import SubMenuWrapper from "src/components/Box/SubMenuWrapper";
import { enumWorkspace } from "src/types/enumeration";

import { getMenuItems, MenuItem, MenuItems } from "./items";
import SidebarMenuItem from "./SidebarMenuItem";

const renderSidebarMenuItems = ({ items, path }: { items: MenuItem[]; path: string }): JSX.Element => (
  <SubMenuWrapper>
    <List component="div">
      {/* @ts-ignore */}
      {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
    </List>
  </SubMenuWrapper>
);

const reduceChildRoutes = ({ ev, path, item }: { ev: JSX.Element[]; path: string; item: MenuItem }): Array<JSX.Element> => {
  const key = item.name;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false,
          },
          path
        )
      : false;

    ev.push(
      <SidebarMenuItem key={key} openParent={partialMatch} name={item.name} icon={item.icon} link={item.link} badge={item.badge} badgeTooltip={item.badgeTooltip}>
        {renderSidebarMenuItems({
          path,
          items: item.items,
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(<SidebarMenuItem key={key} name={item.name} link={item.link} badge={item.badge} badgeTooltip={item.badgeTooltip} icon={item.icon} />);
  }

  return ev;
};

type Props = { workspace: enumWorkspace };

const SidebarMenu: FC<Props> = ({ workspace }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const menuItems: MenuItems | undefined = getMenuItems(workspace);
  const theme = useTheme();

  return (
    <>
      {menuItems && (
        <MenuWrapper key={menuItems.heading}>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky sx={{ fontSize: "15px !important", paddingBottom: `${theme.spacing(1)} !important` }}>
                {t(menuItems.heading)}
              </ListSubheader>
            }
          >
            {renderSidebarMenuItems({
              items: menuItems.items,
              path: location.pathname,
            })}
          </List>
        </MenuWrapper>
      )}
    </>
  );
};

export default SidebarMenu;
