import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { HEIGHT_LOGO, WIDTH_LOGO } from "src/constants/constants";
import { PureLightTheme } from "src/theme/PureLightTheme";

const useStyles = makeStyles({
  listItem: {
    "&:hover": {
      backgroundColor: PureLightTheme.colors.success,
      color: PureLightTheme.colors.alpha.white["100"],
      "& $listItemIconHover": {
        color: `${PureLightTheme.colors.success} !important`,
      },
    },
  },
  // DO NOT REMOVE EMPTY CLASS
  listItemIconHover: {},
  isSelected: {
    backgroundColor: PureLightTheme.colors.alpha.black["100"],
    color: PureLightTheme.colors.alpha.white["100"],
    "&:hover": {
      color: PureLightTheme.colors.alpha.black["100"],
      backgroundColor: PureLightTheme.colors.success,
    },
  },
  isIconSelected: {
    color: PureLightTheme.colors.alpha.white["100"],
  },
  containerImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: PureLightTheme.spacing(2),
    paddingBottom: PureLightTheme.spacing(2),
  },
  image: { width: WIDTH_LOGO, height: HEIGHT_LOGO, cursor: "pointer", padding: PureLightTheme.spacing(1) },
  imageClientDO: { height: 50, marginTop: PureLightTheme.spacing(2) },
  list: {
    width: 300,
  },
});

type Props = {
  closeDrawer: () => void;
  isOpenDrawer: boolean;
  drawerItemsTop: Array<{
    text: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    pathName: string;
    action?: () => void | Promise<boolean>;
  }>;
  drawerItemsBottom: Array<{
    text: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    pathName: string;
    action?: () => void | Promise<boolean>;
  }>;
};

export default function DrawerMenu({ closeDrawer, isOpenDrawer, drawerItemsTop, drawerItemsBottom }: Props) {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Drawer anchor="left" open={isOpenDrawer} onClose={closeDrawer}>
      <div className={classes.list} role="presentation" onClick={closeDrawer} onKeyDown={closeDrawer}>
        <div className={classes.containerImage}>
          <img alt="logo_header" src="/logo192.png" className={classes.image} onClick={() => navigate("/")} />
        </div>
        <List>
          {drawerItemsTop.map((drawerItem, index) => {
            const Icon = drawerItem.icon;
            return (
              <ListItem button key={index} onClick={drawerItem.action} selected={location?.pathname === drawerItem.pathName}>
                <ListItemIcon className={classes.listItemIconHover}>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={drawerItem.text} />
              </ListItem>
            );
          })}
        </List>
        <Divider />
        <List>
          {drawerItemsBottom.map((drawerItem, index) => {
            const Icon = drawerItem.icon;
            return (
              <ListItem button key={index} onClick={drawerItem.action} selected={location?.pathname === drawerItem.pathName}>
                <ListItemIcon className={classes.listItemIconHover}>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={drawerItem.text} />
              </ListItem>
            );
          })}
        </List>
      </div>
    </Drawer>
  );
}
