import { Box, styled } from '@mui/material';

const MenuUserBox = styled(Box)(
  ({ theme }) => `
          background: ${theme.colors.alpha.black[5]};
          padding: ${theme.spacing(2)};
  `
);

export default MenuUserBox;
