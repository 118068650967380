import { FC, useEffect } from 'react';

import { Box, useTheme } from '@mui/material';
import NProgress from 'nprogress';
import SpinnerContainer from 'src/components/Spinner/SpinnerContainer';

const SuspenseLoader: FC = () => {
  const theme = useTheme();
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        width: '100%'
      }}
    >
      <SpinnerContainer />
    </Box>
  );
};

export default SuspenseLoader;
