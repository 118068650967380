import { AppBar, Grid, IconButton, List, ListItem, ListItemText, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { HEIGHT_LOGO, WIDTH_LOGO } from "src/constants/constants";
import { PureLightTheme } from "src/theme/PureLightTheme";
import DefaultTooltip from "../Tooltip/DefaultTooltip";
import DrawerMenu from "./DrawerMenu";
import EndSectionDesktopHeader from "./EndSectionDesktopHeader";
import MenuIcon from "@mui/icons-material/Menu";
import { getDrawerItemsByRole } from "src/accessControl/route";
import useAuth from "src/hooks/useAuth";
import { UserContext } from "src/providers/UserProvider";

const sxStyles = {
  appBar: {
    backgroundColor: PureLightTheme.colors.alpha.white["100"],
  },
  menuItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 40,
    paddingTop: PureLightTheme.spacing(1),
    paddingBottom: PureLightTheme.spacing(1),
    [PureLightTheme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  menuItemImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  desktopContainerItems: {
    marginLeft: PureLightTheme.spacing(2),
  },
  list: {
    padding: 0,
    display: "flex",
    flexDirection: "row",
    height: "100%",
    [PureLightTheme.breakpoints.down("md")]: {
      display: "none",
    },
  },
};

const useStyles = makeStyles({
  image: {
    cursor: "pointer",
    width: WIDTH_LOGO,
    height: HEIGHT_LOGO,
    padding: PureLightTheme.spacing(1),
    marginRight: PureLightTheme.spacing(2),
    [PureLightTheme.breakpoints.down("md")]: {
      display: "none",
    },
  },
});

export default function Header() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { logout } = useContext(UserContext);
  const classes = useStyles();
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  const openDrawer = () => setOpenDrawer(true);
  const closeDrawer = () => setOpenDrawer(false);

  const { drawerItems1, drawerItems2 } = getDrawerItemsByRole(isAuthenticated, navigate, logout);

  return (
    <>
      <AppBar position="sticky" sx={sxStyles.appBar}>
        <Toolbar>
          <Grid container direction="row">
            <Grid item>
              <Grid container sx={sxStyles.desktopContainerItems} direction="row" justifyContent="flex-start">
                <Grid item sx={sxStyles.menuItem}>
                  <DefaultTooltip title={t("acca_menu_tooltip") as string}>
                    <IconButton color="default" onClick={openDrawer}>
                      <MenuIcon />
                    </IconButton>
                  </DefaultTooltip>
                </Grid>
                <Grid item sx={sxStyles.menuItemImg}>
                  <img alt="logo_header" src="/logo192.png" className={classes.image} onClick={() => navigate("/")} />
                </Grid>
                <Grid item>
                  <List sx={sxStyles.list}>
                    {drawerItems1.map((drawerItem, index) => {
                      return (
                        <ListItem
                          button
                          key={index}
                          onClick={drawerItem.action}
                          selected={location?.pathname === drawerItem.pathName}
                          sx={{
                            "&.MuiListItem-button": {
                              margin: 0,
                              borderRadius: 0,
                              paddingLeft: 2,
                              paddingRight: 2,
                              paddingTop: 2,
                              paddingBottom: 2,
                            },
                            /*            "&.MuiListItem-button": {}, */
                          }}
                        >
                          <ListItemText primary={drawerItem.text} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", flexGrow: 1, marginRight: 3 }}>
              <EndSectionDesktopHeader otherButtons={drawerItems2} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <DrawerMenu closeDrawer={closeDrawer} isOpenDrawer={isOpenDrawer} drawerItemsTop={drawerItems1} drawerItemsBottom={drawerItems2} />
    </>
  );
}
