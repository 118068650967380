import { Box, SxProps, Theme } from '@mui/material';
import { FC } from 'react';
import { PureLightTheme } from 'src/theme/PureLightTheme';

import Spinner from './Spinner';

const sxStyles: Record<string, SxProps<Theme>> = {
  spinnerCell: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: PureLightTheme.spacing(5),
    paddingBottom: PureLightTheme.spacing(5)
  }
};

type Props = {
  sx?: SxProps;
};

const SpinnerContainer: FC<Props> = ({ sx }) => (
  <Box sx={{ ...sxStyles.spinnerCell, ...sx } as SxProps}>
    <Spinner
      fontSize={40}
      borderWidth={4}
      color={PureLightTheme.colors.primary.main}
    />
  </Box>
);

export default SpinnerContainer;
