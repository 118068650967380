import { createContext, Dispatch, useState, FC } from "react";
import { useApolloClient } from "@apollo/client";

import { removeTokenCookie } from "src/auth/session";
import { SetStateAction } from "react";
import { UsersPermissionsMe } from "src/generated/graphql";

const USER_KEY = "user";

type LogicObjectsType = {};

type initalUserProps = {
  user: UsersPermissionsMe | null;
  logicObjects: LogicObjectsType;
  setUserState: Dispatch<SetStateAction<UsersPermissionsMe | null>>;
  setLogicObjectsState: Dispatch<SetStateAction<LogicObjectsType>>;
  logout: ({
    callback,
    state,
  }: {
    callback?: () => void;
    state?: { path?: string };
  }) => void;
  logicStateSetter: (property: keyof LogicObjectsType, newValue: any) => void;
};

const initialUser: initalUserProps = {
  user: localStorage.getItem(USER_KEY)
    ? JSON.parse(localStorage.getItem(USER_KEY) as string)
    : null,
  logicObjects: {},
  setUserState: () => {
    /* DO SOMETHING */
  },
  setLogicObjectsState: () => {
    /* DO SOMETHING */
  },
  logout: ({ callback = () => {} }) => {
    /* DO SOMETHING */
  },
  logicStateSetter: (property: keyof LogicObjectsType, newValue: any) => {
    /* DO SOMETHING */
  },
};
export const UserContext = createContext(initialUser);

type Props = {
  children: JSX.Element;
};

export const UserProvider: FC<Props> = ({ children }) => {
  const apolloClient = useApolloClient();
  const [userState, setUserState] = useState(initialUser.user);

  const [logicObjectsState, setLogicObjectsState] = useState(
    initialUser.logicObjects
  );
  const setUserState2 = (user: UsersPermissionsMe | null) => {
    setUserState(user);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  };

  const logicStateSetter = (
    property: keyof LogicObjectsType,
    newValue: any
  ) => {
    setLogicObjectsState({
      ...logicObjectsState,
      [property]: newValue,
    });
  };

  const logout = ({ callback = () => {} }: { callback?: () => void }) => {
    removeTokenCookie();
    setUserState2(null);
    setLogicObjectsState({ artisan: null });
    localStorage.clear();
    apolloClient
      .resetStore()
      .then(() => {
        callback();
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((error) => {
        callback();
      });
  };

  /*   const [refreshToken] = useMutation<{
    refreshToken: Mutations["refreshToken"];
  }>(RefreshTokenMutation, {
    fetchPolicy: "no-cache",
    onCompleted: (result) => {
      setTokenCookie(result?.refreshToken?.token as string);
      setUserState2(result?.refreshToken?.user as User);
    },
    onError: () => {
      setUserState2(null);
      removeTokenCookie();
      localStorage.clear();
      navigate(LOGIN_PATH);
    },
  }); */

  return (
    <UserContext.Provider
      value={{
        user: userState,
        logicObjects: logicObjectsState,
        setUserState: setUserState2 as Dispatch<
          SetStateAction<UsersPermissionsMe | null>
        >,
        setLogicObjectsState,
        logout,
        logicStateSetter,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
