import { Suspense, lazy, LazyExoticComponent } from "react";

// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteObject } from "react-router";
import {
  ASSETS_PATH,
  AUTHENTICATED_EVENTS_PATH,
  CONTACTS_PATH,
  CONTACT_GROUPS_PATH,
  DASHBOARD_PATH,
  DAYCARE_PATH,
  EMAILS_PATH,
  EVENTS_PATH,
  INDEX_PATH,
  LOGIN_PATH,
  MEMBERS_PATH,
  OFFICE_PATH,
  PARTNERS_PATH,
  PHOTOS_PATH,
  SPONSORS_PATH,
  STATUS_PATH,
  TERRITORY_PATH,
} from "src/accessControl/route";
import SuspenseLoader from "src/components/SuspenseLoader/SuspenseLoader";
import AccentSidebarLayout from "src/layouts/AccentSidebarLayout";
import BaseLayout from "src/layouts/BaseLayout/BaseLayout";
import PrivateRoute from "src/navigation/PrivateRoute";
import PublicRoute from "src/navigation/PublicRoute";

import statusRoutes from "./statusRoutes";

const Loader = (Component: LazyExoticComponent<() => JSX.Element> | JSX.IntrinsicAttributes) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      {/* @ts-ignore */}
      <Component {...props} />
    </Suspense>
  );

const HomePage = Loader(lazy(() => import("src/content/pages/Public/Home/Home") as any));
const OfficePage = Loader(lazy(() => import("src/content/pages/Public/Office/Office") as any));
const PartnersPage = Loader(lazy(() => import("src/content/pages/Public/Partner/Partner") as any));
const EventsPage = Loader(lazy(() => import("src/content/pages/Public/Events/Events") as any));
const PhotosPage = Loader(lazy(() => import("src/content/pages/Public/Photo/Photo") as any));
const TerritoryPage = Loader(lazy(() => import("src/content/pages/Public/Territory/Territory") as any));
const DaycarePage = Loader(lazy(() => import("src/content/pages/Public/Daycare/Daycare") as any));

const LoginPage = Loader(lazy(() => import("src/content/auth/Login/LoginBasic") as any));
const DashboardOverview = Loader(lazy(() => import("src/content/dashboard/Overview/DashboardOverview") as any));
const EmailsPage = Loader(lazy(() => import("src/content/dashboard/Emails/Emails") as any));
const MembersPage = Loader(lazy(() => import("src/content/dashboard/Members/Members") as any));
const ContactGroupsPage = Loader(lazy(() => import("src/content/dashboard/ContactGroups/ContactGroups") as any));
const ContactsPage = Loader(lazy(() => import("src/content/dashboard/Contacts/Contacts") as any));
const SponsorsPage = Loader(lazy(() => import("src/content/dashboard/Sponsors/Sponsors") as any));
const AuthenticatedEventsPage = Loader(lazy(() => import("src/content/dashboard/Events/Events") as any));
const AssetsPage = Loader(lazy(() => import("src/content/dashboard/Assets/Assets") as any));

const Status404 = Loader(lazy(() => import("src/content/pages/Status/Status404/Status404") as any));

const router: RouteObject[] = [
  {
    path: "*",
    element: <Status404 />,
  },
  {
    path: STATUS_PATH,
    children: statusRoutes,
  },
  {
    path: LOGIN_PATH,
    element: (
      <PublicRoute>
        <LoginPage />
      </PublicRoute>
    ),
  },
  {
    path: INDEX_PATH,
    element: <BaseLayout />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: OFFICE_PATH,
        element: <OfficePage />,
      },
      {
        path: PARTNERS_PATH,
        element: <PartnersPage />,
      },
      {
        path: EVENTS_PATH,
        element: <EventsPage />,
      },
      {
        path: PHOTOS_PATH,
        element: <PhotosPage />,
      },
      {
        path: TERRITORY_PATH,
        element: <TerritoryPage />,
      },
      {
        path: DAYCARE_PATH,
        element: <DaycarePage />,
      },
    ],
  },
  {
    path: "",
    element: (
      <PrivateRoute>
        <AccentSidebarLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: DASHBOARD_PATH,
        element: <DashboardOverview />,
      },
      {
        path: EMAILS_PATH,
        element: <EmailsPage />,
      },
      {
        path: MEMBERS_PATH,
        element: <MembersPage />,
      },
      {
        path: CONTACT_GROUPS_PATH,
        element: <ContactGroupsPage />,
      },
      {
        path: CONTACTS_PATH,
        element: <ContactsPage />,
      },
      {
        path: SPONSORS_PATH,
        element: <SponsorsPage />,
      },
      {
        path: AUTHENTICATED_EVENTS_PATH,
        element: <AuthenticatedEventsPage />,
      },
      {
        path: ASSETS_PATH,
        element: <AssetsPage />,
      },
    ],
  },
];

export default router;
