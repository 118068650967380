import { useContext, useRef, useState, FC, Dispatch, SetStateAction } from "react";

import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";

import { Box, ListItemIcon, Button, Divider, List, ListItem, ListItemText, Popover, FormControl, InputLabel, MenuItem, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import MenuUserBox from "src/components/Box/MenuUserBox";
import UserBoxText from "src/components/Box/UserBoxText";
import CustomSelect from "src/components/Select/CustomSelect";
import UserBoxDescription2 from "src/components/Typography/UserBoxDescription2";
import UserBoxLabel2 from "src/components/Typography/UserBoxLabel2";
import { UserContext } from "src/providers/UserProvider";
import { enumWorkspace } from "src/types/enumeration";
import { workspaceOptions } from "../SidebarMenu/items";

type Props = {
  workspace: enumWorkspace;
  setWorkspace: Dispatch<SetStateAction<enumWorkspace>>;
};

const SidebarTopSection: FC<Props> = ({ workspace, setWorkspace }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { logout } = useContext(UserContext);

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleClose = (): void => {
    setOpen(false);
  };

  const onLogout = () => {
    logout({});
  };

  const onChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    setWorkspace(event?.target?.value as enumWorkspace);
    /*   if (event?.target?.value) {
      const option = options.find((e) => e.value === event?.target?.value);
      if (option?.redirectTo) {
        navigate(option?.redirectTo);
      }
    } */
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="workspace-label">{t("acca_workspace_label")}</InputLabel>
        <CustomSelect labelId="workspace-label" id="workspace-select" value={workspace} label={t("acca_workspace_label")} onChange={onChange}>
          {workspaceOptions.map((option, index) => {
            const Icon = option?.icon;
            return (
              <MenuItem key={index} value={option?.value}>
                <ListItemIcon>
                  <Icon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{option?.text}</ListItemText>
              </MenuItem>
            );
          })}
        </CustomSelect>
      </FormControl>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210,
          }}
          display="flex"
        >
          <UserBoxText>
            <UserBoxLabel2 className="popoverTypo" variant="body1"></UserBoxLabel2>
            <UserBoxDescription2 className="popoverTypo" variant="body2">
              {/* {artisan?.jobtitle} */}
            </UserBoxDescription2>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0,
          }}
        />
        <List
          sx={{
            p: 1,
          }}
          component="nav"
        >
          <ListItem
            onClick={() => {
              handleClose();
            }}
            button
            to={`/${location.pathname.split("/")[1]}/management/users/single/1`}
            component={NavLink}
          >
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary={t("acca_profile")} />
          </ListItem>
        </List>
        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={onLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1,
              }}
            />
            {t("acca_sign_out")}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default SidebarTopSection;
