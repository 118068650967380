import { Box, Card, Typography, Container, Button } from "@mui/material";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { INDEX_PATH } from "src/accessControl/route";
import MainContent from "src/components/Box/MainContent";

const Status404: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Status - 404</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center">
            {/*             <img alt="404" height={180} src="/static/images/status/404.svg" /> */}
            <Typography
              variant="h2"
              sx={{
                my: 2,
              }}
            >
              {t("acca_status_page_doesnt_exist")}
            </Typography>
            <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              sx={{
                mb: 4,
              }}
            >
              {t("acca_status_page_doesnt_exist_subtitle")}
            </Typography>
          </Box>
          <Container maxWidth="sm">
            <Card
              sx={{
                textAlign: "center",
                mt: 3,
                p: 4,
              }}
            >
              <Button href={INDEX_PATH} variant="outlined">
                {t("acca_homepage")}
              </Button>
            </Card>
          </Container>
        </Container>
      </MainContent>
    </>
  );
};

export default Status404;
