import { FC } from "react";
import LogoWrapperSign from "../Link/LogoWrapperSign";
import LogoACCA from "../Svg/LogoACCA";

const Logo: FC = () => {

  return (
    <LogoWrapperSign>
      <LogoACCA />
    </LogoWrapperSign>
  );
};

export default Logo;
