import { styled, Link } from '@mui/material';

const LogoWrapperSign = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        align-items: center;
        text-decoration: none;
        margin: 0 auto;
        width: 100%;
        font-weight: ${theme.typography.fontWeightBold};

        svg {
          width: 100%;
          height: auto;
        }
`
);

export default LogoWrapperSign;
