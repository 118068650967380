import i18next from "i18next";
import { matchPath } from "react-router";
import { NavigateFunction } from "react-router-dom";

import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import MonochromePhotosIcon from "@mui/icons-material/MonochromePhotos";
import PublicIcon from "@mui/icons-material/Public";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from "@mui/icons-material/Home";
import SecurityIcon from "@mui/icons-material/Security";
import EventIcon from "@mui/icons-material/Event";
/* import AccountCircleIcon from "@mui/icons-material/AccountCircle"; */
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LoginIcon from "@mui/icons-material/Login";

import {
  INDEX_PAGE_RESSOURCE,
  LOGIN_PAGE_RESSOURCE,
  STATUS_COMING_SOON_RESSOURCE,
  STATUS_MAINTENANCE_RESSOURCE,
  STATUS_500_RESSOURCE,
  STATUS_RESSOURCE,
  DASHBOARD_RESSOURCE,
  OFFICE_RESSOURCE,
  PARTNERS_RESSOURCE,
  EVENTS_RESSOURCE,
  PHOTOS_RESSOURCE,
  TERRITORY_RESSOURCE,
  DAYCARE_RESSOURCE,
  EMAILS_RESSOURCE,
  MEMBERS_RESSOURCE,
  CONTACT_GROUPS_RESSOURCE,
  CONTACTS_RESSOURCE,
  SPONSORS_RESSOURCE,
  AUTHENTICATED_EVENTS_RESSOURCE,
  ASSETS_RESSOURCE,
} from "./rules";

export const CREATE_PATH = "create";
export const UPDATE_PATH = "update";
export const LISTING_PATH = "listing";
export const DETAILS_PATH = "details";
export const STORE_PATH = "store";

export const S_500_PATH = "500";
export const MAINTENANCE_PATH = "maintenance";
export const COMING_SOON_PATH = "coming-soon";

/** Common */
export const INDEX_PATH = "/";
export const LOGIN_PATH = "/login";
export const STATUS_PATH = "/status";
export const STATUS_500_PATH = STATUS_PATH + "/" + S_500_PATH;
export const STATUS_MAINTENANCE_PATH = STATUS_PATH + "/" + MAINTENANCE_PATH;
export const STATUS_COMING_SOON_PATH = STATUS_PATH + "/" + COMING_SOON_PATH;
export const OFFICE_PATH = "/bureau";
export const PARTNERS_PATH = "/partenaires";
export const EVENTS_PATH = "/evenements";
export const PHOTOS_PATH = "/photos";
export const TERRITORY_PATH = "/territoire";
export const DAYCARE_PATH = "/garderie";

/* Authenticated user */
export const DASHBOARD_PATH = "/dashboard";
export const EMAILS_PATH = "/emails";
export const MEMBERS_PATH = "/membres";
export const CONTACT_GROUPS_PATH = "/groupes";
export const CONTACTS_PATH = "/contacts";
export const SPONSORS_PATH = "/sponsors";
export const AUTHENTICATED_EVENTS_PATH = "/events";
export const ASSETS_PATH = "/assets";

export const routes = [
  { pattern: INDEX_PATH, ressource: INDEX_PAGE_RESSOURCE },
  { pattern: LOGIN_PATH, ressource: LOGIN_PAGE_RESSOURCE },
  { pattern: STATUS_PATH, ressource: STATUS_RESSOURCE },
  { pattern: STATUS_500_PATH, ressource: STATUS_500_RESSOURCE },
  { pattern: STATUS_MAINTENANCE_PATH, ressource: STATUS_MAINTENANCE_RESSOURCE },
  { pattern: STATUS_COMING_SOON_PATH, ressource: STATUS_COMING_SOON_RESSOURCE },
  { pattern: OFFICE_PATH, ressource: OFFICE_RESSOURCE },
  { pattern: PARTNERS_PATH, ressource: PARTNERS_RESSOURCE },
  { pattern: EVENTS_PATH, ressource: EVENTS_RESSOURCE },
  { pattern: PHOTOS_PATH, ressource: PHOTOS_RESSOURCE },
  { pattern: TERRITORY_PATH, ressource: TERRITORY_RESSOURCE },
  { pattern: DAYCARE_PATH, ressource: DAYCARE_RESSOURCE },
  /* Authenticated user */
  { pattern: DASHBOARD_PATH, ressource: DASHBOARD_RESSOURCE },
  { pattern: EMAILS_PATH, ressource: EMAILS_RESSOURCE },
  { pattern: MEMBERS_PATH, ressource: MEMBERS_RESSOURCE },
  { pattern: CONTACT_GROUPS_PATH, ressource: CONTACT_GROUPS_RESSOURCE },
  { pattern: CONTACTS_PATH, ressource: CONTACTS_RESSOURCE },
  { pattern: SPONSORS_PATH, ressource: SPONSORS_RESSOURCE },
  {
    pattern: AUTHENTICATED_EVENTS_PATH,
    ressource: AUTHENTICATED_EVENTS_RESSOURCE,
  },
  { pattern: ASSETS_PATH, ressource: ASSETS_RESSOURCE },
];

export const getPathnameRessource = (pathname: string) => {
  let result = "NOT FOUND";
  routes.map((e: any) => {
    const match = matchPath(e.pattern, pathname);

    if (match) {
      result = e.ressource;
    }
    return null;
  });

  return result;
};

export const getDrawerItems1ByRole = (
  isAuthenticated: boolean,
  navigate: NavigateFunction
) => {
  return [
    {
      text: i18next.t("acca_home"),
      icon: HomeIcon,
      pathName: INDEX_PATH,
      action: () => navigate(INDEX_PATH),
    },
    {
      text: i18next.t("acca_office"),
      icon: GroupsIcon,
      pathName: OFFICE_PATH,
      action: () => navigate(OFFICE_PATH),
    },
    {
      text: i18next.t("acca_events"),
      icon: EventIcon,
      pathName: EVENTS_PATH,
      action: () => navigate(EVENTS_PATH),
    },
    {
      text: i18next.t("acca_territory"),
      icon: PublicIcon,
      pathName: TERRITORY_PATH,
      action: () => navigate(TERRITORY_PATH),
    },
    {
      text: i18next.t("acca_daycare"),
      icon: SecurityIcon,
      pathName: DAYCARE_PATH,
      action: () => navigate(DAYCARE_PATH),
    },
    {
      text: i18next.t("acca_partners"),
      icon: SupervisedUserCircleIcon,
      pathName: PARTNERS_PATH,
      action: () => navigate(PARTNERS_PATH),
    },
    {
      text: i18next.t("acca_photos"),
      icon: MonochromePhotosIcon,
      pathName: PHOTOS_PATH,
      action: () => navigate(PHOTOS_PATH),
    },
  ];
};

export const getDrawerItems2ByRole = (
  isAuthenticated: boolean,
  navigate: NavigateFunction,
  logout: ({
    callback,
    state,
  }: {
    callback?: () => void;
    state?: { path?: string };
  }) => void
) => {
  let tabs = [];
  if (!isAuthenticated) {
    tabs.push({
      text: i18next.t("acca_login"),
      icon: LoginIcon,
      pathName: LOGIN_PATH,
      action: () => navigate(LOGIN_PATH),
    });
  }
  if (isAuthenticated) {
    tabs.push({
      text: i18next.t("acca_dashboard"),
      icon: DashboardIcon,
      pathName: DASHBOARD_PATH,
      action: () => navigate(DASHBOARD_PATH),
    });
    /*     tabs.push({
      text: i18next.t("acca_account"),
      icon: AccountCircleIcon,
      pathName: "",
      action: () => {},
    }); */
    tabs.push({
      text: i18next.t("acca_logout"),
      icon: LogoutIcon,
      pathName: "",
      action: () =>
        logout({
          callback: () => navigate(LOGIN_PATH),
        }),
    });
  }

  return tabs;
};

export const getDrawerItemsByRole = (
  isAuthenticated: boolean,
  navigate: NavigateFunction,
  logout: ({
    callback,
    state,
  }: {
    callback?: () => void;
    state?: { path?: string };
  }) => void
) => {
  const drawerItems1 = getDrawerItems1ByRole(isAuthenticated, navigate);
  const drawerItems2 = getDrawerItems2ByRole(isAuthenticated, navigate, logout);
  return { drawerItems1, drawerItems2 };
};
