import { UsersPermissionsMe } from "src/generated/graphql";
import rules from "./rules";

const check = (user: UsersPermissionsMe | null, resource: string) => {
  const role = user && user.role?.name;

  const permissions = rules[role as string];

  if (!permissions) {
    return false;
  }
  if (permissions.includes(resource)) {
    return true;
  }

  return false;
};

export default check;
