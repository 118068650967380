import { FC, ReactNode } from "react";

import { Box, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";

import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import Footer from "src/components/Footer/Footer";

interface AccentSidebarLayoutProps {
  children?: ReactNode;
}

const AccentSidebarLayout: FC<AccentSidebarLayoutProps> = ({ children }) => {
  const theme = useTheme();

  return (
    <>
      <Header />
      <Sidebar />
      <Box
        sx={{
          position: "relative",
          zIndex: 5,
          flex: 1,
          display: "flex",
          pt: `${theme.header.height}`,
          [theme.breakpoints.up("lg")]: {
            pl: `${theme.sidebar.width}`,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              justifyContent: "space-between",
            }}
          >
            {children || <Outlet />}
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AccentSidebarLayout;
